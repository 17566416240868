import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../redux/reducers";

export const LogOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(onLogout());
    dispatch(userLogout());
    localStorage.clear();
    navigate("/");
  }, [dispatch, navigate]);
  return <></>;
};

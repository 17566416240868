/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import { useState, FC } from "react";
import { Container } from "../../../../globalstyled";
import {
  CreateVentsInput,
  MatColor,
  Material,
  UpdateMaterial,
} from "../../../../redux/types/Material";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, IconButton } from "@mui/material";
import {
  CreateVents,
  GetMaterials,
  UpdateCurrentMaterial,
} from "../../../../redux/api/materials";
import { toast } from "react-toastify";
import PlaceHolder from "../../../../images/placeholder.jpeg";
import { useDispatch } from "react-redux";
import { setMaterials } from "../../../../redux/reducers";

interface UserTableProps {
  materials: Material[];
  onUploadGutters: Function;
}

export const GuttersTable: FC<UserTableProps> = ({
  materials,
  onUploadGutters,
}) => {
  const dispatch = useDispatch();

  const [isAddGutters, setIsAddGutters] = useState(false);
  const [editGutters, setEditGutters] = useState(false);
  const [currentGutter, setCurrentGutter] = useState<Material>();

  const material_type = "gutters";
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [home_photo, setHomePhoto] = useState("");
  const [warranty, setWarranty] = useState("");

  const [keyword, setKeyword] = useState("");

  const [viewColors, setViewColors] = useState(false);
  const [colors, setColors] = useState<MatColor[]>([]);

  const resetForm = () => {
    setBrand("");
    setProduct("");
    setDescription("");
    setPrice("");
    setUnit("");
    setHomePhoto("");
    setWarranty("");
  };

  const viewImage = (imageName?: string) => {
    // console.log(url);
    return imageName ? imageName : PlaceHolder;
  };

  const onTapCreateGutter = async () => {
    const input: CreateVentsInput = {
      material_type,
      brand,
      product,
      price: price,
      description,
      coverage_size: 0,
      unit: unit,
      home_photo,
      warranty: warranty,
    };
    try {
      const result = await CreateVents(input);
      if (result) {
        setIsAddGutters(false);
        toast("New Gutter created!", {
          type: "success",
        });
        resetForm();
      } else {
        setIsAddGutters(false);
        toast("Error while creating Gutter!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchMaterials = async () => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials(material_type);
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (!currentGutter) {
      toast("Error while updating gutter!", {
        type: "error",
      });
      return;
    }
    const input: UpdateMaterial = {
      price: price,
      product: product,
      unit: unit,
      warranty: warranty,
    };
    try {
      const result = await UpdateCurrentMaterial(currentGutter.id, input);
      if (result === 200) {
        toast("Gutter Updated Successfully!", {
          type: "success",
        });
        await onFetchMaterials();
        setEditGutters(false);
        resetForm();
      } else {
        toast("Error while updating gutter!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const AddGutters = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.GLAS_BLUE,
              height: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Create Gutters
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />

          <ColDiv>
            <Lbl title="Warranty" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={warranty}
              placeholder="Warranty"
              onChange={setWarranty}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />

          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Image Url" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={home_photo}
              placeholder="image url"
              onChange={setHomePhoto}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => setIsAddGutters(false)}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Create"}
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
            onTap={() => onTapCreateGutter()}
            width={120}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const EditGutters = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.GLAS_BLUE,
              height: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Edit Gutter
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />

          <ColDiv>
            <Lbl title="Warranty" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={warranty}
              placeholder="Warranty"
              onChange={setWarranty}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => {
              setCurrentGutter(undefined);
              setEditGutters(false);
            }}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Save"}
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
            onTap={() => onTapSave()}
            width={120}
          />
          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(materials)) {
      return materials.filter((item) => {
        return item.description.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return materials;
  };

  const MaterialsTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <RowDiv
          style={{
            marginTop: 20,
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <TxtInput
            width={280}
            placeholder={`Search gutters`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Upload"}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              onTap={() => {
                setIsAddGutters(false);
                onUploadGutters();
              }}
              width={80}
              height={40}
            />
            <TapButton
              title={"Add Gutter"}
              bgColor={AppCSS.GLAS_BLUE}
              onTap={() => {
                setEditGutters(false);
                setIsAddGutters(true);
              }}
              width={120}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <table style={{ width: "100%" }}>
            <tr
              style={{
                height: "40px",
                background: AppCSS.GLAS_BLUE,
                color: "white",
              }}
            >
              <th
                style={{
                  width: "180px",
                  borderRadius: 10,
                }}
              >
                Brand
              </th>
              <th
                style={{
                  width: "120px",
                  borderRadius: 10,
                }}
              >
                Warranty
              </th>
              <th
                style={{
                  width: "280px",
                  borderRadius: 10,
                }}
              >
                Product Name
              </th>
              <th
                style={{
                  width: "340px",
                  borderRadius: 10,
                }}
              >
                Description
              </th>
              <th
                style={{
                  width: "80px",
                  borderRadius: 10,
                }}
              >
                Price
              </th>
              <th
                style={{
                  width: "80px",
                  borderRadius: 10,
                }}
              >
                Colors
              </th>
              <th
                style={{
                  width: "80px",
                  borderRadius: 10,
                }}
              >
                Action
              </th>
            </tr>
            {filterLeads()
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const key = `key-${row.id}`;
                return (
                  <tr key={key} style={{ width: "100%", height: "40px" }}>
                    <td>
                      <p>{row.brand}</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {row.warranty ? (
                        <Chip
                          label={"View"}
                          variant="outlined"
                          onClick={() => {
                            window.open(`${row.warranty}`, "__blank");
                          }}
                          style={{
                            background: AppCSS.GLAS_GREEN,
                          }}
                        />
                      ) : (
                        <p style={{ fontSize: 12 }}>No Warranty</p>
                      )}
                    </td>
                    <td>
                      <p>{row.product}</p>
                    </td>
                    <td>
                      <p
                        style={{
                          color: AppCSS.BLACK,
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          fontSize: "0.9rem",
                          margin: 0,
                          padding: 10,
                        }}
                      >
                        {row.description}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>${row.price}</p>
                    </td>
                    <td>
                      <Chip
                        label={`${
                          Array.isArray(row.Colors) ? row.Colors.length : 0
                        } Items`}
                        variant="outlined"
                        onClick={() => {
                          setColors(row.Colors);
                          setViewColors(true);
                        }}
                        style={{
                          width: 80,
                          background: AppCSS.GLAS_GREEN,
                        }}
                      />
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setPrice(row.price);
                          setProduct(row.product);
                          setDescription(row.description);
                          setCurrentGutter(row);
                          setEditGutters(true);
                        }}
                        aria-label=""
                        style={{
                          display: "flex",
                          width: 48,
                          height: 48,
                          border: 1,
                          borderColor: AppCSS.GRAY_MILD,
                        }}
                      >
                        <EditIcon style={{ color: AppCSS.GLAS_GREEN }} />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
          </table>
        </RowDiv>
      </div>
    );
  };

  // new
  const materialsContainer = () => {
    return (
      <Container>
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            {/* {displayMaterials()} */}
            {Array.isArray(materials) && MaterialsTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  const MaterialColors = (colors: MatColor[]) => {
    return (
      <table style={{ width: "100%" }}>
        <tr
          style={{
            height: "40px",
            background: AppCSS.RED_MILD,
          }}
        >
          <th>Color</th>
          <th>Home Photo</th>
          <th>Action</th>
        </tr>
        {colors.map((row, index) => {
          const key = `key-${row.id}`;
          return (
            <tr key={key} style={{ width: "100%", height: "40px" }}>
              <td>
                <p>{row.color}</p>
              </td>
              <td>
                <p>
                  <img
                    src={viewImage(row.home_photo)}
                    width={80}
                    height={60}
                    alt="logo"
                  />
                </p>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label=""
                  style={{
                    display: "flex",
                    width: 48,
                    height: 48,
                    border: 1,
                    borderColor: AppCSS.GRAY_MILD,
                  }}
                >
                  <EditIcon style={{ color: AppCSS.RED }} />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </table>
    );
  };

  const colorsContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Lbl title="Color Variants" size={22} />
            <TapButton
              title="← Materials"
              onTap={() => {
                setViewColors(false);
                setColors([]);
              }}
              bgColor={AppCSS.RED_MILD}
              borderColor={AppCSS.WHITE}
              color={AppCSS.RED}
              width={120}
              radius={10}
            />
          </RowDiv>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(colors) && MaterialColors(colors)}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  if (isAddGutters) {
    return AddGutters();
  } else if (editGutters) {
    return EditGutters();
  } else {
    if (viewColors) {
      return colorsContainer();
    } else {
      return materialsContainer();
    }
  }
};

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { AppCSS } from "..";
import { ColDiv } from "./misc.styled";

interface SelectOptionProps {
  onHandleChange: Function;
  value: any;
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  onHandleChange,
  value,
}) => {
  const [item, setItem] = useState<any>(0);

  const menuItems = () => {
    return [
      { title: "12:00 am", value: 0 },
      { title: "1:00 am", value: 1 },
      { title: "2:00 am", value: 2 },
      { title: "3:00 am", value: 3 },
      { title: "4:00 am", value: 4 },
      { title: "5:00 am", value: 5 },
      { title: "6:00 am", value: 6 },
      { title: "7:00 am", value: 7 },
      { title: "8:00 am", value: 8 },
      { title: "9:00 am", value: 9 },
      { title: "10:00 am", value: 10 },
      { title: "11:00 am", value: 11 },
      { title: "12:00 pm", value: 12 },
      { title: "1:00 pm", value: 13 },
      { title: "2:00 pm", value: 14 },
      { title: "3:00 pm", value: 15 },
      { title: "4:00 pm", value: 16 },
      { title: "5:00 pm", value: 17 },
      { title: "6:00 pm", value: 18 },
      { title: "7:00 pm", value: 19 },
      { title: "8:00 pm", value: 20 },
      { title: "9:00 pm", value: 21 },
      { title: "10:00 pm", value: 22 },
      { title: "11:00 pm", value: 23 },
    ].map((option) => {
      return (
        <MenuItem
          style={{
            fontSize: 16,
          }}
          value={option.value}
        >
          {option.title}
        </MenuItem>
      );
    });
  };

  const onHandleItemChange = (value: any) => {
    setItem(value);
  };

  return (
    <ColDiv
      style={{
        background: AppCSS.GRAY_MILD,
        borderRadius: "5px",
        padding: "5px",
        paddingLeft: "10px",
        width: "96%",
      }}
    >
      <FormControl variant="filled" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          label="Age"
          onChange={(e) => onHandleItemChange(e.target.value)}
          disableUnderline
          style={{
            background: "none",
          }}
        >
          {menuItems()}
        </Select>
      </FormControl>
    </ColDiv>
  );
};

export const SelectPosition: React.FC<SelectOptionProps> = ({
  onHandleChange,
  value,
}) => {
  const [item, setItem] = useState<any>("");

  const menuItems = () => {
    return [
      { title: "Sales", value: "sales" },
      { title: "Admin", value: "admin" },
    ].map((option) => {
      return (
        <MenuItem
          style={{
            fontSize: 16,
          }}
          value={option.value}
        >
          {option.title}
        </MenuItem>
      );
    });
  };

  const onHandleItemChange = (newValue: any) => {
    setItem(newValue);
    onHandleChange(newValue);
  };

  return (
    <ColDiv
      style={{
        background: AppCSS.GRAY_MILD,
        borderRadius: "5px",
        padding: "5px",
        paddingLeft: "10px",
        width: "96%",
      }}
    >
      <FormControl variant="filled" fullWidth>
        {item === "" ? (
          <InputLabel
            disableAnimation
            shrink={false}
            focused={false}
            id="item_type_label"
            style={{
              marginTop: -10,
            }}
          >
            Select Position
          </InputLabel>
        ) : null}

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          label="position"
          placeholder="Position"
          onChange={(e) => onHandleItemChange(e.target.value)}
          disableUnderline
          style={{
            background: "none",
          }}
        >
          {menuItems()}
        </Select>
      </FormControl>
    </ColDiv>
  );
};

import { CreateVentsInput, UpdateMaterial } from "../types/Material";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";

export const GetMaterials = async (matType: string) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/materials/${matType}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const CreateVents = async (input: CreateVentsInput) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${BASE_URL}/materials`, {
      ...input,
    });
    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GetProjectMaterials = async (pId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/project/${pId}/materials`);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const UpdateCurrentMaterial = async (
  matId: number,
  input: UpdateMaterial
) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(`${BASE_URL}/material/${matId}`, {
      ...input,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return false;
  }
};

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { FC, useRef, useState } from "react";
import { AppCSS, Lbl, Spacer, TapButton } from "../../../../components";
import { SFileUpload } from "../../../../components/input/input.styled";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileIcon from "../../../../images/file_icon.png";
import axios from "axios";
import { GetUploadUrl, UpdateDocs } from "../../../../redux/api";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { Docs } from "../../../../redux/types";
import { FileOpen } from "@mui/icons-material";
import { Estimate } from "../../../../redux/types/Estimate";
import { Prompt } from "../../../../components/dialogue/Dialogue";
import { RegenerateEstimate } from "../../../../redux/api/estimate";
import App from "../../../../App";
import { FormatString } from "../../../../utils";

interface EstimateProps {
  onFetchProject: Function;
  projectId: number;
  estimate?: Estimate;
  docs?: Docs[];
}

export const EstimateDetails: FC<EstimateProps> = ({
  onFetchProject,
  estimate,
  projectId,
  docs,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [docType, setDocType] = useState("material_estimate");
  const [updateFile, setUpdateFile] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  const onTapRegenerateEstimate = async () => {
    setOpenConfirm(false);
    try {
      const status = await RegenerateEstimate(projectId);
      if (status === 200) {
        onFetchProject();
        toast("Estimate generated successfully!", {
          type: "success",
        });
      } else {
        toast("Failed to generate estimnate!", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Failed to generate estimnate!", {
        type: "error",
      });
    }
  };

  const onGetSignedUrl = async () => {
    if (!fileRef.current?.files) {
      return;
    }

    try {
      const file = fileRef.current.files[0].name;
      const response = await GetUploadUrl(file, projectId);
      if (response) {
        const {
          data: {
            aws: { URL },
            file,
          },
        } = response;
        setUpdateFile(file);
        if (URL) {
          setUploadUrl(URL);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onUpload = async () => {
    if (!fileRef.current?.files) {
      return;
    }
    const file = fileRef.current.files[0];
    var options = {
      headers: {
        "Content-Type": "application/pdf",
        "X-Amz-Acl": "public-read",
        "Content-Disposition": "inline",
      },
    };

    try {
      const response = await axios.put(uploadUrl, file, options);
      if (response.status === 200) {
        setUpdateFile("");
        setDocType("");
        await UpdateDocs(updateFile, projectId, docType);
        toast("Doc uploaded successfully", {
          type: "success",
        });
        onFetchProject();
      }
    } catch (error) {
      setUpdateFile("");
      setDocType("");
      toast("File upload error!", {
        type: "error",
      });
    }
  };

  return (
    <RowDiv>
      <Prompt
        message="Regenerate Estimate will override the existing calculation!"
        onConfirm={() => onTapRegenerateEstimate()}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />
      <ColDiv
        style={{
          width: "70%",
        }}
      >
        <table style={{ width: "100%" }}>
          <tr
            style={{
              width: "50%",
              height: "40px",
              background: AppCSS.GLAS_BLUE,
            }}
          >
            <th style={{ color: "white", borderRadius: 10, maxWidth: "50%" }}>
              Document Name
            </th>
            <th style={{ color: "white", borderRadius: 10, maxWidth: "50%" }}>
              Document Link
            </th>
          </tr>
          {Array.isArray(docs) &&
            docs
              .filter(
                (item) =>
                  item.file_type.toLowerCase() === "estimate" ||
                  item.file_type.toLowerCase() === "estimated_materials"
              )
              .map((item, index) => (
                <tr key={index} style={{ width: "100%", height: "40px" }}>
                  <td
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                      maxWidth: "50%",
                    }}
                  >
                    <p>{FormatString(item.file_type)}</p>
                  </td>
                  <td
                    style={{
                      paddingLeft: 5,
                      textAlign: "center",
                      maxWidth: "50%",
                    }}
                  >
                    <a
                      href={`${item.file_url}`}
                      target="_blank"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 15,
                        color: AppCSS.GLAS_BLUE,
                      }}
                    >
                      <p>View File</p>
                    </a>
                  </td>
                </tr>
              ))}
        </table>
        <RowDiv
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TapButton
            height={42}
            width={220}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            onTap={() => setOpenConfirm(true)}
            title="Regenerate the estimate"
          />
        </RowDiv>
      </ColDiv>

      <ColDiv
        style={{
          width: "30%",
          marginLeft: 40,
        }}
      >
        <RowDiv
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColDiv>
            <Lbl
              title="Upload Custom Estimate"
              color={AppCSS.GRAY_DARK}
              size={13}
            />
          </ColDiv>
          <ColDiv
            style={{
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SFileUpload
              style={{
                border: updateFile.length > 0 ? `3px solid #A9CBB7` : "none",
              }}
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.click();
                }
              }}
            >
              <input
                type="file"
                id="file"
                ref={fileRef}
                onChange={onGetSignedUrl}
                style={{
                  display: "none",
                }}
              />
              <FileOpen
                titleAccess="Select File"
                color="warning"
                style={{ width: 32, height: 32, color: AppCSS.GLAS_GREEN }}
              />
            </SFileUpload>
          </ColDiv>
        </RowDiv>

        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <TapButton
            bgColor={
              updateFile.length < 1 ? AppCSS.GRAY_LIGHT : AppCSS.GLAS_BLUE
            }
            disable={updateFile.length < 1}
            width={120}
            height={42}
            title="Upload"
            onTap={() => onUpload()}
          />
        </RowDiv>
      </ColDiv>
    </RowDiv>
  );
};

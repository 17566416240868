import { AxiosResponse } from "axios";
import axios from "axios";
import { BASE_URL } from "./appConst";
import { ResponseModel } from "../types/common";

export const handleResponse = async (
  response: AxiosResponse
): Promise<ResponseModel> => {
  if (response.status === 200) {
    return {
      msg: "success",
      data: response.data.data,
    };
  }
  return { msg: "error occured" };
};

export const axiosAuth = () => {
  const token = localStorage.getItem("token") as string;
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { FC, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import { Project } from "../../../../redux/types";
import moment from "moment";

interface InstallationProps {
  project?: Project;
}

export const InstallationDetails: FC<InstallationProps> = ({ project }) => {
  const [isEdit, setIsEdit] = useState(false);

  const onTapUpdateProfile = () => {
    setIsEdit(false);
  };

  const displayInstallation = () => {
    const commonStyles = {
      alignItems: "center",
      border: "2px solid",
      borderColor: AppCSS.GLAS_BLUE,
      padding: "10px",
      borderRadius: "4px",
    };
    if (project && project.installation_date) {
      return (
        <RowDiv style={commonStyles}>
          <TxtInput
            value={moment(project.installation_date).format("LL")}
            placeholder="Installation date"
            onChange={() => {}}
          />
        </RowDiv>
      );
    }

    return (
      <RowDiv style={commonStyles}>
        <p
          style={{
            fontSize: 16,
            margin: 0,
            padding: 0,
            color: AppCSS.BLACK,
            marginRight: 20,
          }}
        >
          Not yet scheduled
        </p>
      </RowDiv>
    );
  };

  return (
    <>
      <RowDiv
        style={{
          display: "row",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ColDiv
          style={{
            width: "60%",
            marginRight: "100px",
          }}
        >
          <Spacer size={1} direction="col" />
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl
                title="Installation Date"
                color={AppCSS.GRAY_DARK}
                size={18}
                bold={600}
              />
              {displayInstallation()}
            </ColDiv>
            <Spacer size={1} direction="row" />
          </RowDiv>
        </ColDiv>
        <ColDiv
          style={{
            width: "60%",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <ColDiv></ColDiv>

          <Spacer size={2} direction="col" />
        </ColDiv>
      </RowDiv>
      <RowDiv style={{ justifyContent: "flex-end" }}>
        <TapButton
          title="Save"
          onTap={() => onTapUpdateProfile()}
          bgColor={AppCSS.GLAS_BLUE}
          width={120}
          height={38}
        />
      </RowDiv>
    </>
  );
};

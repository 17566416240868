import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppCSS, Spacer, TapButton } from "..";
import { Lbl } from "../labels";
import { ColDiv, RowDiv } from "../misc/misc.styled";

export const AppAlertBox = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Your party is assigned to table!");

  // useEffect(() => {
  //   // if (appAlert) {
  //   //   // setOpen(appAlert.open);
  //   //   // setTitle(appAlert.title);
  //   // } else {
  //   //   setOpen(false);
  //   // }
  // }, [appAlert]);

  const onHandleDismiss = () => {
    // dispatch(onHideAppAlert());
  };

  return (
    <Dialog
      open={open !== undefined ? open : false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          background: "none",
        }}
      >
        <ColDiv
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            background: AppCSS.WHITE,
            justifyContent: "center",
          }}
        >
          <Spacer size={1} direction="col" />
          <Lbl title={`${title}`} align="center" size={16} bold={600} />
          <Spacer size={2} direction="col" />

          <TapButton width={220} onTap={() => onHandleDismiss()} title="Okay" />
        </ColDiv>
      </DialogContent>
    </Dialog>
  );
};

interface TipAlertProps {
  open: boolean;
  onOk: Function;
}

export const ReActiveAlert: React.FC<TipAlertProps> = ({ open, onOk }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
        }}
      >
        <ColDiv
          style={{
            alignItems: "center",
            background: AppCSS.WHITE,
            justifyContent: "space-between",
          }}
        >
          <Lbl
            title={`Continue Live Interaction...`}
            align="center"
            size={11}
          />
          <Spacer direction="col" size={2} />
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TapButton onTap={() => onOk()} title="Continue" />
          </RowDiv>
        </ColDiv>
        <Spacer direction="col" size={1} />
      </DialogContent>
    </Dialog>
  );
};

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useState } from "react";
import { AppCSS, Spacer } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { Tab, Tabs } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { setMaterials } from "../../../redux/reducers";
import { Container } from "../../../globalstyled";
import { GetMaterials } from "../../../redux/api/materials";
import { Material } from "../../../redux/types/Material";
import { RoofMaterials } from "./Roofs";
import { VentsMaterials } from "./Vents";
import { GutterMaterials } from "./Gutters/Index";
import { IncludesMaterials } from "./Includes/Index";
import { PipeJacksMaterials } from "./PipeJacks";
import { FlashingMaterials } from "./Flashing";
import { LaborInfo } from "./Labor";
import AppLoader from "../../../components/loader";

interface DashboardProps {}

export const Materials: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 1:
        onFetchMaterials("vents");
        break;
      case 2:
        onFetchMaterials("pipe-jacks");
        break;
      case 3:
        onFetchMaterials("flashing");
        break;
      case 4:
        onFetchMaterials("gutters");
        break;
      case 5:
        onFetchMaterials("includes");
        break;
      default:
        onFetchMaterials("roof");
        break;
    }
  };

  const userReducer = useAppSelector((state) => state.userReducer);
  const { materials } = userReducer;

  const [addMaterial, setAddMaterial] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onFetchMaterials("roof")
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching Roof materials:", error);
        setIsLoading(false);
      });
  }, []);

  const onFetchMaterials = async (matType: string) => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials(matType);
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayTabs = () => {
    switch (currentTab) {
      case 0:
        return (
          <RoofMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("roof");
            }}
          />
        );

      case 1:
        return (
          <VentsMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("vents");
            }}
          />
        );
      case 2:
        return (
          <PipeJacksMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("pipe-jacks");
            }}
          />
        );
      case 3:
        return (
          <FlashingMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("flashing");
            }}
          />
        );
      case 4:
        return (
          <GutterMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("gutters");
            }}
          />
        );

      case 5:
        return (
          <IncludesMaterials
            materials={materials}
            onDone={() => {
              onFetchMaterials("includes");
            }}
          />
        );
      // case 6:
      //   return (
      //     <LaborInfo
      //       materials={materials}
      //       onDone={() => {
      //         onFetchMaterials("labor");
      //       }}
      //     />
      //   );
      default:
        return <div>Other tabs</div>;
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  return (
    <Container>
      <Spacer size={2} direction="col" />
      <ColDiv
        style={{
          display: "row",
          maxWidth: "1100px",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <RowDiv>
          <Lbl title="Materials" size={22} />
        </RowDiv>
        <Spacer />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            background: "none",
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="Materials Tab"
          >
            <Tab
              label="Shingles"
              style={{
                background: currentTab === 0 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 0 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            <Tab
              label="Vents"
              style={{
                background: currentTab === 1 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 1 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            <Tab
              label="Pipe Jacks"
              style={{
                background: currentTab === 2 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 2 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            <Tab
              label="Flashing"
              style={{
                background: currentTab === 3 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 3 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            <Tab
              label="Gutters"
              style={{
                background: currentTab === 4 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 4 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            <Tab
              label="Included Items"
              style={{
                background: currentTab === 5 ? AppCSS.WHITE : AppCSS.WHITE,
                color: currentTab === 5 ? AppCSS.GLAS_BLUE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            />
            {/* <Tab
              label="Labor"
              style={{
                background: currentTab === 6 ? AppCSS.BLUE : AppCSS.WHITE,
                color: currentTab === 6 ? AppCSS.WHITE : AppCSS.BLACK,
                borderRadius: 10,
              }}
            /> */}
          </Tabs>
          {displayTabs()}
        </div>
      </ColDiv>
    </Container>
  );
};

import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const ProjectIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TabsBox = styled(Box)`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
  font-size: 12px @media only screen and (min-width: 640px) {
    font-size: 16px;
  }
`;

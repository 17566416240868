import { FC, useRef, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
  ButtonWithIcon,
} from "../../../../components";
import { SFileUpload } from "../../../../components/input/input.styled";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import axios from "axios";
import { GetUploadUrl, UpdateDocs } from "../../../../redux/api";
import { toast } from "react-toastify";
import { Docs, Project } from "../../../../redux/types";
import { Download, DownloadDoneOutlined, FileOpen } from "@mui/icons-material";
import { UpdateSignedDocs } from "../../../../redux/api/estimate";

interface DocumentsProps {
  onFetchProject: Function;
  project?: Project;
  docs?: Docs[];
}

export const DocumentDetails: FC<DocumentsProps> = ({
  onFetchProject,
  project,
  docs,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [docType, setDocType] = useState("");
  const [updateFile, setUpdateFile] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");

  console.log(docs);
  const onGetSignedUrl = async () => {
    if (!fileRef.current?.files) {
      return;
    }

    try {
      const file = fileRef.current.files[0].name;
      console.log(file);
      const response = await GetUploadUrl(file, project.id);
      console.log(response);
      if (response) {
        const {
          data: {
            aws: { URL },
            file,
          },
        } = response;
        setUpdateFile(file);
        if (URL) {
          setUploadUrl(URL);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onUpload = async () => {
    if (!fileRef.current?.files) {
      return;
    }
    const file = fileRef.current.files[0];
    var options = {
      headers: {
        "Content-Type": "application/pdf",
        "X-Amz-Acl": "public-read",
        "Content-Disposition": "inline",
      },
    };

    try {
      const response = await axios.put(uploadUrl, file, options);
      if (response.status === 200) {
        setUpdateFile("");
        setDocType("");
        await UpdateDocs(updateFile, project.id, docType);
        toast("Doc uploaded successfully", {
          type: "success",
        });
        onFetchProject();
      }
    } catch (error) {
      setUpdateFile("");
      setDocType("");
      toast("File upload error!", {
        type: "error",
      });
    }
  };

  function formatString(str) {
    return str
      .split("_") // Split string on underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
      .join(" "); // Join the words with spaces
  }

  const onTapUpdateSignedDocs = async (docType: string) => {
    try {
      const status = await UpdateSignedDocs(project.id, docType);
      if (status === 200) {
        toast(`Updated signed copy of ${docType}!`, {
          type: "success",
        });
        onFetchProject();
      }
    } catch (error) {
      toast("File upload error!", {
        type: "error",
      });
    }
  };

  const getEncodedUrl = (url) => {
    const urlParts = url.split("/");
    const fileName = urlParts.pop();
    const encodedFileName = encodeURIComponent(fileName);
    return `${urlParts.join("/")}/${encodedFileName}`;
  };

  return (
    <>
      <RowDiv
        style={{
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ColDiv
          style={{
            width: "70%",
          }}
        >
          <table style={{ width: "100%" }}>
            <tr
              style={{
                width: "50%",
                height: "40px",
                background: AppCSS.GLAS_BLUE,
              }}
            >
              <th style={{ color: "white", borderRadius: 10, maxWidth: "50%" }}>
                Document Name
              </th>
              <th style={{ color: "white", borderRadius: 10, maxWidth: "50%" }}>
                Document Link
              </th>
              <th style={{ color: "white", borderRadius: 10, width: 120 }}>
                Action
              </th>
            </tr>
            {Array.isArray(docs) &&
              docs
                .filter((item) => item.file_type !== "image")
                .map((item, index) => (
                  <tr key={index} style={{ width: "100%", height: "40px" }}>
                    <td
                      style={{
                        paddingLeft: 20,
                        textAlign: "left",
                        maxWidth: "50%",
                      }}
                    >
                      <p>{formatString(item.file_type)}</p>
                    </td>
                    <td
                      style={{
                        paddingLeft: 20,
                        textAlign: "left",
                        maxWidth: "50%",
                      }}
                    >
                      <a
                        href={getEncodedUrl(item.file_url)}
                        target="_blank"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 13,
                          color: AppCSS.GLAS_BLUE,
                        }}
                      >
                        <p>View File</p>
                      </a>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {(item.file_type.toLowerCase() === "invoice" ||
                        item.file_type.toLowerCase() === "coc" ||
                        item.file_type.toLowerCase() === "estimate") && (
                        <ButtonWithIcon
                          icon={<DownloadDoneOutlined />}
                          onTap={() => onTapUpdateSignedDocs(item.file_type)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
          </table>
        </ColDiv>
        <Spacer size={3} direction="row" />
        <ColDiv
          style={{
            width: "30%",
          }}
        >
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ColDiv>
              <Lbl title="Document Type" color={AppCSS.GRAY_DARK} size={13} />
              <RowDiv>
                {" "}
                <TxtInput
                  value={docType}
                  placeholder="Enter a document title"
                  onChange={setDocType}
                />
                <SFileUpload
                  style={{
                    border:
                      updateFile.length > 0 ? `3px solid #A9CBB7` : "none",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    if (fileRef.current) {
                      fileRef.current.click();
                    }
                  }}
                >
                  <input
                    type="file"
                    id="file"
                    ref={fileRef}
                    onChange={onGetSignedUrl}
                    style={{
                      display: "none",
                    }}
                  />
                  <FileOpen
                    titleAccess="Select File"
                    color="warning"
                    style={{ width: 32, height: 32, color: AppCSS.GLAS_GREEN }}
                  />
                </SFileUpload>
              </RowDiv>
            </ColDiv>
          </RowDiv>

          <RowDiv>
            <ColDiv
              style={{
                width: "30%",
              }}
            >
              <Spacer size={1} direction="col" />
              <TapButton
                bgColor={
                  updateFile.length < 1 ? AppCSS.GRAY_LIGHT : AppCSS.GLAS_BLUE
                }
                disable={updateFile.length < 1}
                width={120}
                height={42}
                title="Upload"
                onTap={() => onUpload()}
              />
            </ColDiv>
          </RowDiv>
        </ColDiv>
      </RowDiv>
      <Spacer size={3} direction="col" />
    </>
  );
};

/* eslint-disable jsx-a11y/alt-text */
import { Spacer, TxtInput, AppCSS } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import { useDispatch } from "react-redux";
import { WaitlistModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import moment from "moment";

import { Box, Drawer } from "@mui/material";

import {
  RowIconDiv,
  DashboardIcon,
  SearchInputBox,
  SearchIcon,
  PreviousIcon,
} from "../dashboard.styled";
import searchIcon from "../../../images/searchIcon.png";
import previousIcon from "../../../images/previousIcon.png";
import nextIcon from "../../../images/nextIcon.png";
import WaitListsTableHeaderCard from "../../../components/table/CustomTableHeaderCard";
import WaitListsTableBodyCard from "../../../components/table/CustomTableBodyCard";
import { WAITLISTSTABLEHEADERLIST } from "../../../contants/dashboardHeader";

import DisplayWaitListDetailsCard from "../Leads/DisplayLeadDetailCard";
import cancelIcon from "../../../images/CancelIcon.png";
import { LEAD_DRAWER_DETAILS } from "../../../contants/projectDetails";
import { LeadDrawerDetails } from "../../../types/type";


interface WaitlistTableProps {
  waitlist: WaitlistModel[];
}

export const WaitlistTable: React.FC<WaitlistTableProps> = ({ waitlist }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedWaitList, setSelectedWaitList] = React.useState(null);


  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(waitlist)) {
      return waitlist.filter((item) => {
        return item.first_name.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return waitlist;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filterLeads().length / rowsPerPage) - 1)
    );
  };

  const handleDrawerOpen = (lead: any) => {
    return () => {
      setSelectedWaitList(lead);
      setOpenDrawer(true);
    };
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const showWaitListDetails = () => {
    return (

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            overflowX: "hidden",
          },
        }}
      >
        <div
          onKeyDown={handleDrawerClose}
          style={{
            backgroundColor: AppCSS.WHITE,
            color: "black",
            height: "100%",
            display: "flex",
            width: "440px",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "20px",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <DashboardIcon
              src={cancelIcon}
              onClick={handleDrawerClose}
              style={{
                width: "12px",
                height: "12px",
                cursor: "pointer",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "100px",
                marginRight: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lbl
                title={`Waitlist ${selectedWaitList?.id}`}
                size={16}
                width={80}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              paddingX: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "calc(100% - 20px)",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              {LEAD_DRAWER_DETAILS.map(
                (detail: LeadDrawerDetails, index: number) => (
                  <DisplayWaitListDetailsCard
                    key={`lead drawer details  ${index}`}
                    label={detail.label}
                    value={
                      detail.isDate
                        ? new Date(
                            selectedWaitList?.[detail.key]
                          ).toLocaleDateString()
                        : selectedWaitList?.[detail.key]
                    }
                  />
                )
              )}
            </Box>
          </Box>
        </div>
      </Drawer>
    );
  };

  const WaitlistTable = () => {
    return (

      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
          }}
        >
          <WaitListsTableHeaderCard headers={WAITLISTSTABLEHEADERLIST} />
          {filterLeads()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => {
              return (
                <WaitListsTableBodyCard
                  key={`WaitListsDetails ${rowIndex}`}

                  onViewLeadsDetails={handleDrawerOpen(row)}
                  isViewDetails={true}

                  bodyContent={[
                    {
                      text: `${row.first_name},`,
                      width: "13%",
                    },
                    { text: row.last_name, width: "13%" },
                    {
                      text: `${row.phone}`,
                      width: "16%",
                    },
                    {
                      text: row.email,
                      width: "14%",
                    },
                    {
                      text: row.street_address,
                      width: "22%",
                    },
                    {
                      text: row.city,
                      width: "10%",
                    },
                    {
                      text: `${moment(row.created_at).fromNow(true)} ago`,
                      width: "12%",
                    },
                  ]}
                />
              );
            })}
        </ColDiv>
        <RowDiv
          style={{
            justifyContent: "end",
            marginTop: "20px",
            marginBottom: "5%",
            alignItems: "center",
            marginRight: "10%",
          }}
        >
          <PreviousIcon src={previousIcon} onClick={() => goToPreviousPage()} />
          <span style={{ margin: "0 15px" }}>
            Page {page + 1} of {Math.ceil(filterLeads().length / rowsPerPage)}
          </span>
          <PreviousIcon src={nextIcon} onClick={() => goToNextPage()} />
        </RowDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            justifyContent: "space-around",
            marginLeft: "10px",
            marginRight: "auto",
          }}
        >
          <RowIconDiv
            style={{
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "3%",
              }}
            >
              <DashboardIcon
                src={
                  "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/d47a928e-12f5-43c2-2bd3-ccd1a1d82800/chatImage"
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100px",
                  marginRight: "10px",
                }}
              >
                <Lbl title="Waitlist" size={22} width={80} />
              </Box>
            </Box>
            <SearchInputBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "white",

                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SearchIcon
                src={searchIcon}
                style={{
                  margin: "10px",
                  background: "none",
                }}
                onClick={handleSearchIconClick}
              />
              <TxtInput
                placeholder={`Search First Name`}
                onChange={setKeyword}
                inputRef={inputRef}
                background="white"
              />
            </SearchInputBox>
          </RowIconDiv>

          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(waitlist) && WaitlistTable()}
          </RowDiv>
        </ColDiv>
        {showWaitListDetails()}
      </Container>
    );
  };

  return userContainer();
};

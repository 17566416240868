import styled from "@emotion/styled";
import { AppBar, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export const LogoSmall = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 10px;
`;

export const NavBarIcon = styled.img`
  width: 25px;
  height: 25px;
  "&:hover": {
    color:red;
    background-color: blue;
  
  },
`;

export const NavBarArrowIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const ColDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RestaurantLogo = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

export const NavAppBar = styled(Box)`
  && {
    background-color: black; // Adjust as needed
    // overflow: auto;
    border-right: 1px solid #ccc; // Border to separate navbar from content
  }
`;

export const HomeIcon = styled(IconButton)`
  color: #414141;
  font-weight: bold,
  "&:hover": {
    background: none,
  },`;

export const BackIcon = styled(IconButton)`
  color: #414141;
  font-weight: bold,
  "&:hover": {
    background: none,
  },`;

export const LeftIcon = styled(IconButton)`
  margin-right: 2px;
  // margin-left: "2%";
  color: #414141;
  font-weight: bold,
  "&:hover": {
    background: none,
  },

  /* Custom,
  iPhone Retina */
  @media only screen and (min-width: 320px) {
    // margin-left: 1%;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: 480px) {
    // margin-left: 1%;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) {
    // margin-left: 5%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width: 992px) {
    // margin-left: 5%;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width: 1200px) {
    // margin-left: 5%;
  }
  
`;

export const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export const CenterNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const NavLink = styled(Link)`
  & {
    background: #fff;
    list-style: none;
    color: #414141;
    text-decoration: none;
    padding: 7px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    &:hover {
      background: #3e6990;
      color: #fff;
    }
  }
`;

export const NavLinkNormal = styled(Link)`
  & {
    display: "flex";
    background: #fff;
    list-style: none;
    color: #414141;
    text-decoration: none;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

interface SLblLargeProps extends HTMLAttributes<HTMLLabelElement> {
  mobileFontSize?: number;
  fontSize?: number;
}

export const TinyLbl = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  font-size: 10pt;
  font-weight: "600";
  color: #1a1824;
  text-align: left;
  padding: 3px;
  max-width: 200px;
`;

export const SLblLarge = styled.label<SLblLargeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  font-size: 30pt;
  font-weight: "100";
  color: #1a1824;
  width: 90%;
  text-align: left;
  padding: 3px;
  padding-left: 20px;
  ${({ mobileFontSize, fontSize }) => `
  font-size:  ${mobileFontSize ? mobileFontSize : 16}px; 
  @media screen and (min-width: 640px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) {
    font-size: ${fontSize ? fontSize : 24}px;
  }

  `}
`;

export const SLblHeadline = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 22pt;
  font-weight: "100";
  color: #1a1824;
  width: 90%;
  text-align: center;
  padding: 10px;
  padding-left: 20px;
`;

export const SLblOptions = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  font-size: 16pt;
  font-weight: "100";
  color: #979797;
  width: 90%;
  text-align: left;
  padding: 10px;
`;

export const SLblSmall = styled.label`
  font-size: 12pt;
  color: #979797;
  text-align: center;
  padding: 10px;
  margin: 10px;
  line-height: 140%;
  margin-left: 30px;
  margin-right: 30px;
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Lbl, LblLarge } from "../labels";
import {
  CenterNav,
  ColDiv,
  LeftNav,
  LogoSmall,
  MenuBox,
  RightNav,
  RowDiv,
  SRoundNest,
  SSpacer,
} from "./misc.styled";
import LogoIcon from "../../images/logo.svg";

import { CloseButton, TapButton } from "../buttons";
import { TxtInput } from "../input";
import dayjs from "dayjs";
import { AppCSS, SelectOption } from "..";
import { toast } from "react-toastify";

export * from "./SelectOption";
interface SpacerProps {
  direction?: "row" | "col";
  size?: number;
}

export const Spacer: React.FC<SpacerProps> = ({
  direction = "col",
  size = 2,
}) => {
  return (
    <SSpacer
      sx={{
        mt: direction === "col" ? `${size || 0}rem` : 0,
        ml: direction === "row" ? `${size || 0}rem` : 0,
      }}
    ></SSpacer>
  );
};

export const Nest = (props: any) => {
  return <SRoundNest>{props}</SRoundNest>;
};

interface MenuProps {
  title?: string;
  CenterLogo?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  isBorder?: boolean;
}

export const TopNavBar: React.FC<MenuProps> = ({
  title,
  CenterLogo = false,
  leftIcon,
  rightIcon,
  isBorder,
}) => {
  return (
    <MenuBox style={{ border: isBorder ? "1px solid #efefef" : "none" }}>
      <LeftNav>{leftIcon && leftIcon}</LeftNav>
      <CenterNav>
        {title && <LblLarge title={title} />}
        {CenterLogo && <LogoSmall src={LogoIcon} />}
      </CenterNav>
      <RightNav>{rightIcon && rightIcon}</RightNav>
    </MenuBox>
  );
};

interface DialogProps {
  open: boolean;
  onClose: Function;
  onConfirm: Function;
  partyName: string;
}

export const Prompt: React.FC<DialogProps> = ({
  open,
  onClose,
  partyName,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      <DialogTitle id="alert-dialog-title">Please Confirm!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure joining ${partyName}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>No</Button>
        <Button onClick={() => onConfirm()} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*
<DialogContent
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        
        <ColDiv
          style={{
            alignItems: "center",
          }}
        >
          <TxtInput
            placeholder="Category Name"
            required
            value={name}
            onChange={setName}
          />
          <Lbl
            title="Set Availability Hours"
            color="#626264"
            size={16}
            bold={600}
            align="center"
          />
          <Spacer size={2} direction="col" />
          <RowDiv
            style={{
              justifyContent: "space-between",
            }}
          >
            <DateTextField
              date={startTime}
              onChangeDate={(d: Date) => onChangeStartTime(d)}
            />
            <DateTextField
              date={endTime}
              onChangeDate={(d: Date) => onChangeEndTime(d)}
            />
          </RowDiv>
        </ColDiv>
      </DialogContent>
      
        <Spacer size={2} direction="col" />
      </ColDiv>
      */

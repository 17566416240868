import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AppCSS } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(25, 25, 25, .9)",
      paddingBottom: 50,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
    },
    progress: {
      width: "224px",
      marginBottom: "20px",
      marginTop: "20%",
      borderRadius: "10px",
      height: "8px",
      transition: "width 5s ease-in-out  ",
    },
    bar: {
      backgroundColor: AppCSS.GREEN,
      width: "40px",
    },
    colorPrimary: {
      backgroundColor: AppCSS.GRAY_DARK,
    },
  })
);

interface LoadingProps {
  isLoading?: boolean;
}

const AppLoader: React.FC<LoadingProps> = ({ isLoading = false }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: isLoading ? "block" : "none",
      }}
      className={classes.root}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <LinearProgress
          classes={{
            root: classes.progress,
            bar: classes.bar,
            colorPrimary: classes.colorPrimary,
          }}
        />
        <p style={{ color: "white", fontSize: "1.7rem" }}>Loading...</p>
      </div>
    </div>
  );
};

export default AppLoader;

import { Container } from "@mui/material";
import { AddMaterial } from "../Roofs/AddMaterial";
import { FC, useState } from "react";
import { Material } from "../../../../redux/types/Material";
import { PipeJacksTable } from "./PipeJacksTable";

interface GuttersProps {
  onDone: Function;
  materials?: Material[];
}

export const PipeJacksMaterials: FC<GuttersProps> = ({ onDone, materials }) => {
  const [addMaterial, setAddMaterial] = useState(false);

  const onDisplayAddMaterial = () => {
    setAddMaterial(!addMaterial);
  };

  if (addMaterial) {
    return (
      <Container>
        <AddMaterial
          matType="pipe-jacks"
          onCancel={() => setAddMaterial(false)}
          onTapAdd={() => {
            setAddMaterial(false);
            onDone("pipe-jacks");
          }}
        />
      </Container>
    );
  } else {
    return (
      <PipeJacksTable
        materials={Array.isArray(materials) ? materials : []}
        onUploadPipeJacks={() => onDisplayAddMaterial()}
      />
    );
  }
};

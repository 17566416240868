/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { TapButton, AppCSS, Spacer } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { GetProject } from "../../../redux/api";
import { Docs, Progress, Project } from "../../../redux/types";
import {
  setEstimate,
  setEstimateAddOns,
  setProjectDetails,
} from "../../../redux/reducers";

import { CertificateOfCompletion } from "./CertificateOfCompletion";
import { ProgressSlider } from "./ProgressSlider";
import { CustomerDetails } from "./CustomerDetails";
import { InsuranceDetails } from "./InsuranceDetails";
import { MaterialDetails } from "./MaterialDetails";
import { RoofDetails } from "./RoofDetails";
import { InstallationDetails } from "./InstallationDetails";
import { PaymentDetails } from "./PaymentDetails";
import { DocumentDetails } from "./Documents";
import { Material } from "../../../redux/types/Material";
import { EstimateDetails } from "./Estimate";
import { GetEstimate, GetEstimateAddOns } from "../../../redux/api/estimate";
import { Estimate, EstimateAddOns } from "../../../redux/types/Estimate";
import { DeleteProject } from "./Delete Project";

import { Tab, Tabs, Box } from "@mui/material";
import { ProjectIcon, TabsBox } from "./projectsDetails.styled";
import LeftArrowIcon from "../../../images/leftArrowIcon.png";
import AppLoader from "../../../components/loader/index";
import { TABLABELS } from "../../../contants/projectDetails";

interface DashboardProps {}

const tabLabels = [
  "Customer Details",
  "Roof Details",
  "Insurance Scope",
  "Materials",
  "Estimate",
  "Installation Details",
  "Payment Details",
  "Documents",
  "COC / Invoice",
];

export const ProjectDescription: React.FC<DashboardProps> = ({}) => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projectDetails, estimate, estimateAddOns } = useAppSelector(
    (state) => state.userReducer
  );
  const { project, progress, docs, projectMaterials } = projectDetails;
  const [value, setValue] = useState(0);
  const [viewProgress, setViewProgress] = useState(false);
  const [userFullName, setUsersFullName] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("===>>>> render");

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      Promise.all([
        onFetchProject(),
        onFetchEstimate(),
        onFetchEstimateAddOns(),
      ])
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    }
  }, [id]);

  const onFetchProject = async () => {
    try {
      const data = await GetProject(Number(id));
      const userData = data as {
        message: string;
        project: Project;
        progress: Progress[];
        docs: Docs[];
        materials: Material[];
      };
      if (userData.project) {
        setUsersFullName(
          `${userData?.project?.user?.first_name} ${userData?.project?.user?.last_name}`
        );
        setProjectId(`${userData?.project?.id}`);

        dispatch(
          setProjectDetails({
            project: userData.project,
            progress: userData.progress,
            docs: userData.docs,
            projectMaterials: userData.materials,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchEstimate = async () => {
    try {
      const data = await GetEstimate(Number(id));
      const response = data.data as Estimate;
      if (response) {
        dispatch(setEstimate(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchEstimateAddOns = async () => {
    try {
      const data = await GetEstimateAddOns(Number(id));
      console.log(data);
      const response = data.data as EstimateAddOns[];
      if (response) {
        dispatch(setEstimateAddOns(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderTabPanel = (index: number) => {
    switch (index) {
      case 0:
        return (
          <CustomerDetails
            onFetchProgress={onFetchProject}
            project={project}
            progress={progress}
          />
        );
      case 1:
        return <RoofDetails project={project} />;
      case 2:
        return (
          <InsuranceDetails
            estimate={estimate}
            project={project}
            estimateAddOns={estimateAddOns}
            onFetchEstimate={onFetchEstimate}
            docs={docs}
          />
        );
      case 3:
        return <MaterialDetails materials={projectMaterials} />;
      case 4:
        return (
          <EstimateDetails
            onFetchProject={onFetchEstimate}
            projectId={+id}
            estimate={estimate}
            docs={docs}
          />
        );
      case 5:
        return <InstallationDetails project={project} />;
      case 6:
        return (
          <PaymentDetails
            docs={docs}
            project={project}
            progress={progress}
            onCompleted={onFetchProject}
          />
        );
      case 7:
        return (
          <DocumentDetails
            onFetchProject={onFetchProject}
            project={project}
            docs={docs}
          />
        );
      case 8:
        return (
          <CertificateOfCompletion
            project={project}
            docs={docs}
            estimateAddOns={estimateAddOns}
            onFetchProject={onFetchProject}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: AppCSS.GRAY_LIGHTER,
        justifyContent: "center",
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        height: "100vh",
      }}
    >
      <ColDiv
        style={{
          width: "97%",
          height: "95%",
          marginBottom: "5%",
        }}
      >
        <RowDiv
          style={{
            width: "100%",
            height: "80px",
          }}
        >
          <ColDiv
            style={{
              width: "20%",
              height: "100%",
            }}
          >
            <RowDiv
              style={{
                justifyContent: "start",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <ProjectIcon
                src={LeftArrowIcon}
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              />
              <ColDiv
                style={{
                  fontSize: "36px",
                }}
              >
                <Lbl
                  title={userFullName}
                  size={30}
                  bold={400}
                  mobileFontSize={12}
                />
                <Lbl title={projectId} size={12} bold={200} />
              </ColDiv>
            </RowDiv>
          </ColDiv>
          <ColDiv
            style={{
              width: "80%",
              height: "100%",
            }}
          >
            <ProgressSlider progress={progress} />
          </ColDiv>
        </RowDiv>
        <RowDiv
          style={{
            width: "100%",
            height: "calc(100% - 100px)",
            gap: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <ColDiv
            style={{
              width: "20%",
              height: "100%",
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
          >
            <TabsBox
              sx={{
                width: "100%",
                height: "100%",
                marginTop: "20px",
                overflowY: "auto",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                    background: "none",
                    border: "none",
                  },
                  hidden: true,
                }}
                aria-label="full width tabs example"
                variant="scrollable"
              >
                {TABLABELS.map((label, index) => (
                  <Tab
                    key={index}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      width: "100%",
                      height: "38px",
                      background: value === index ? AppCSS.GREEN : "inherit",
                      marginRight: "30px",
                      marginBottom: "2px",
                      marginTop: "2px",
                      justifyContent: "center",
                      alignContent: "left",
                      alignItems: "flex-start",
                      color: AppCSS.BLACK,
                      "&.Mui-selected": {
                        color: AppCSS.BLACK,
                        background: AppCSS.GREEN,
                        borderRadius: "10px",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </TabsBox>
          </ColDiv>
          <ColDiv
            style={{
              width: "80%",
              height: "100%",
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Box
              style={{
                width: "97%",
                marginRight: "3%",
                padding: "20px",
              }}
            >
              {renderTabPanel(value)}
            </Box>

            <DeleteProject
              project={project}
              docs={docs}
              onDelete={() => navigate(-1)}
            />
          </ColDiv>
        </RowDiv>
      </ColDiv>
    </div>
  );
};

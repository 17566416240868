/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import { SFileUpload } from "../../../../components/input/input.styled";
import { axiosAuth } from "../../../../redux/api/common";
import { BASE_URL } from "../../../../redux/api/appConst";
import { FileOpen } from "@mui/icons-material";

interface CreateCompanyProps {
  onTapAdd: Function;
  onCancel: Function;
  matType:
    | "roof"
    | "vents"
    | "gutters"
    | "includes"
    | "pipe-jacks"
    | "flashing"
    | "labor";
}

export const AddMaterial: React.FC<CreateCompanyProps> = ({
  onTapAdd,
  onCancel,
  matType,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");

  const onUploadMaterialCSV = async () => {
    if (fileName === "") {
      alert("please select a csv file to upload materials");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", fileRef.current.files[0]);
      try {
        const auth = axiosAuth();
        const response = await auth({
          method: "post",
          url: `${BASE_URL}/materials/${matType}`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (Array.isArray(response.data.data)) {
          onTapAdd();
        } else {
          alert("Failed to process material file!");
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadForm = () => {
    return (
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <SFileUpload
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          <input
            type="file"
            id="file"
            ref={fileRef}
            onChange={(e) => {
              setFileName(e.target.files[0].name);
            }}
            style={{
              display: "none",
            }}
          />
          <Lbl
            title={fileName || "select a file to upload"}
            color={AppCSS.GRAY}
            size={10}
          />
          <img src={UploadIcon} height="22px" width="22px" />
        </SFileUpload> */}
        <SFileUpload
          style={{
            border: fileName.length > 0 ? `3px solid #A9CBB7` : "none",
          }}
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          <input
            type="file"
            id="file"
            ref={fileRef}
            onChange={(e) => {
              setFileName(e.target.files[0].name);
            }}
            style={{
              display: "none",
            }}
          />
          <FileOpen
            titleAccess="Select File"
            color="warning"
            style={{ width: 32, height: 32, color: AppCSS.GLAS_GREEN }}
          />
        </SFileUpload>
      </div>
    );
  };

  const hostContainer = () => {
    return (
      <ColDiv>
        <Spacer size={3} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            display: "row",
            maxWidth: "900px",

            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <ColDiv>
              <Lbl
                title={`Upload ${matType.toLowerCase()} materials`}
                size={25}
                bold={600}
              />
            </ColDiv>
          </RowDiv>
        </RowDiv>

        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv style={{ justifyContent: "center" }}>
          <p style={{ fontWeight: "bold" }}>Only upload CSV files</p>
        </RowDiv>
        <RowDiv
          style={{
            width: "100%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ColDiv
            style={{
              marginTop: "60px",
              width: "100%",

              justifyContent: "center",
            }}
          >
            {uploadForm()}
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            width: "100%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => onCancel()}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            disable={fileName.length < 1 ? true : false}
            title={"Upload"}
            bgColor={fileName.length > 0 ? AppCSS.GLAS_BLUE : AppCSS.GRAY_LIGHT}
            onTap={() => onUploadMaterialCSV()}
            width={120}
            height={40}
          />
        </RowDiv>
      </ColDiv>
    );
  };

  return hostContainer();
};

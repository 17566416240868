import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const MAP_API_KEY = "AIzaSyDSNPYnmx5Khm-fhMdlVndbJ0v9U6Ckz-E";

export interface AddressInput {
  label: string;
  value: {
    description: string;
    matched_substrings: [{ length: number; offset: number }];
    place_id: string;
    reference: string;
    structured_formatting: {
      main_text: string;
      main_text_matched_substrings: [{ length: number; offset: number }];
      secondary_text: string;
    };
    terms: [{ offset: number; value: string }];
    types: [string];
  };
}

export const MapPage = () => {
  const [location, setLocation] = useState<AddressInput>();

  const onPlaceChanged = async (pickedPlace: any) => {
    const address = pickedPlace as AddressInput;
    setLocation(address);
  };

  return (
    <>
      <h1>Map Page</h1>
      <div className=" flex-col flex mx-2 sm:mx-4">
        <GooglePlacesAutocomplete
          apiKey={MAP_API_KEY}
          selectProps={{
            onChange: onPlaceChanged,
            placeholder: (
              <p className="flex justify-center w-full">Enter your address</p>
            ),
            styles: {
              option: (provided) => ({
                ...provided,
                color: "#eeff2",
                alignItems: "center",
                justifyContent: "center",
              }),
              input: (provided) => ({
                ...provided,
                width: 350,
                height: 30,
                "@media (max-width: 640px)": {
                  width: 250, // Width for mobile screens
                },
              }),
            },
          }}
        />
      </div>
    </>
  );
};

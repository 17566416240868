/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import { useState, FC } from "react";
import { Container } from "../../../../globalstyled";
import {
  CreateVentsInput,
  Material,
  UpdateMaterial,
} from "../../../../redux/types/Material";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import {
  CreateVents,
  GetMaterials,
  UpdateCurrentMaterial,
} from "../../../../redux/api/materials";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMaterials } from "../../../../redux/reducers";

interface UserTableProps {
  materials: Material[];
  onUploadIncludes: Function;
}

export const IncludesTable: FC<UserTableProps> = ({
  materials,
  onUploadIncludes,
}) => {
  const dispatch = useDispatch();

  const [isAddIncludes, setIsAddIncludes] = useState(false);
  const [editIncludes, setEditIncludes] = useState(false);
  const [currentInclude, setCurrentInclude] = useState<Material>();

  const material_type = "includes";
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [coverage_size, setCoverageSize] = useState(0);
  const [unit, setUnit] = useState("");

  const [keyword, setKeyword] = useState("");

  const onTapCreateVents = async () => {
    const input: CreateVentsInput = {
      material_type,
      brand,
      product,
      price: price,
      description,
      coverage_size: +coverage_size,
      unit: unit,
      home_photo: "",
    };
    try {
      const result = await CreateVents(input);
      if (result) {
        setIsAddIncludes(false);
        toast("New Vent created!", {
          type: "success",
        });
      } else {
        setIsAddIncludes(false);
        toast("Error while creating Vent!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const AddIncludes = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.GLAS_BLUE,
              height: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Create Includes
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => setIsAddIncludes(false)}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Create"}
            bgColor={AppCSS.GLAS_BLUE}
            onTap={() => onTapCreateVents()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const onFetchMaterials = async () => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials("includes");
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (!currentInclude) {
      toast("Error while updating Includes Item!", {
        type: "error",
      });
      return;
    }
    const input: UpdateMaterial = {
      price: price,
      coverage_size: `${coverage_size}`,
      unit: unit,
    };
    try {
      const result = await UpdateCurrentMaterial(currentInclude.id, input);
      if (result === 200) {
        toast("Include Updated Successfully!", {
          type: "success",
        });
        await onFetchMaterials();
        setEditIncludes(false);
      } else {
        toast("Error while updating Includes Item!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const EditIncludes = () => {
    if (!currentInclude) return <></>;

    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.BLUE,
              height: 40,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Edit Includes Item
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              disable
              value={currentInclude.brand}
              placeholder="Brand"
              onChange={() => {}}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              disable
              value={currentInclude.product}
              placeholder="Product"
              onChange={() => {}}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              disable
              value={currentInclude.description}
              placeholder="Description"
              onChange={() => {}}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => {
              setIsAddIncludes(false);
              setEditIncludes(false);
              setCurrentInclude(undefined);
            }}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.RED}
            color={AppCSS.RED}
          />
          <TapButton
            title={"Save"}
            bgColor={AppCSS.RED}
            onTap={() => onTapSave()}
            width={120}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(materials)) {
      return materials.filter((item) => {
        return item.description.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return materials;
  };

  const MaterialsTable = () => {
    return (
      <div style={{ width: "100%", marginBottom: "5%" }}>
        <RowDiv
          style={{
            marginTop: 20,
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <TxtInput
            width={280}
            placeholder={`Search included items`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Upload"}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              onTap={() => {
                setIsAddIncludes(false);
                onUploadIncludes();
              }}
              width={80}
              height={40}
            />
            <TapButton
              title={"Add Includes"}
              bgColor={AppCSS.GLAS_BLUE}
              onTap={() => setIsAddIncludes(true)}
              width={120}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <table style={{ width: "100%" }}>
            <tr
              style={{
                height: "40px",
                background: AppCSS.GLAS_BLUE,
                color: "white",
              }}
            >
              {/* <th style={{ borderRadius: 10 }}>ID</th> */}
              <th style={{ borderRadius: 10 }}>Brand</th>
              {/* <th style={{ borderRadius: 10 }}>Material</th> */}
              <th style={{ borderRadius: 10 }}>Description</th>
              <th style={{ borderRadius: 10 }}>Price</th>
              <th style={{ borderRadius: 10 }}>Coverage Size</th>
              <th style={{ borderRadius: 10 }}>Unit</th>
              <th style={{ borderRadius: 10 }}>Action</th>
            </tr>
            {filterLeads()
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const key = `key-${row.id}`;
                return (
                  <tr key={key} style={{ width: "100%", height: "40px" }}>
                    {/* <td>
                      <p>{row.id}</p>
                    </td> */}
                    <td>
                      <p>{row.brand}</p>
                    </td>
                    {/* <td>
                      <p>Material will come here</p>
                    </td> */}
                    <td>
                      <p
                        style={{
                          color: AppCSS.BLACK,
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          fontSize: "0.9rem",
                          margin: 0,
                          padding: 10,
                        }}
                      >
                        {row.description}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>${row.price}</p>
                    </td>
                    <td>
                      <p>{row.coverage_size}</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>{row.unit}</p>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsAddIncludes(false);
                          setPrice(row.price);
                          setCoverageSize(row.coverage_size);
                          setUnit(row.unit);
                          setCurrentInclude(row);
                          setEditIncludes(true);
                        }}
                        aria-label=""
                        style={{
                          display: "flex",
                          width: 48,
                          height: 48,
                          border: 1,
                          borderColor: AppCSS.GRAY_MILD,
                        }}
                      >
                        <EditIcon style={{ color: AppCSS.GLAS_GREEN }} />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
          </table>
        </RowDiv>
      </div>
    );
  };

  const materialsContainer = () => {
    return (
      <Container>
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {Array.isArray(materials) && MaterialsTable()}
        </ColDiv>
      </Container>
    );
  };

  if (isAddIncludes) {
    return AddIncludes();
  } else if (editIncludes) {
    return EditIncludes();
  } else {
    return materialsContainer();
  }
};

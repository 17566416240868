import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const LeadIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SearchIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const SearchInputBox = styled(Box)`
  width: 100%;
  height: 35px;
  @media only screen and (min-width: 480px) {
    width: 342px;
  }
`;

export const PreviousIcon = styled.img`
  width: 8px;
  height: 12px;
`;

export const RowLeadIconDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

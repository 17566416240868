import { FC } from "react";
import { Progress } from "../../../../redux/types";
import { Stepper, Step, StepLabel } from "@mui/material";
import { AppCSS } from "../../../../components";

interface ProgresProps {
  progress?: Progress[];
}

export const ProgressSlider: FC<ProgresProps> = ({ progress }) => {
  if (!Array.isArray(progress)) {
    return <></>;
  }

  let activeStep = 0;
  progress.forEach((item, index) => {
    if (item.completed === true) {
      activeStep = index + 1;
    }
    return;
  });

  return (
    <div
      style={{
        margin: 10,
        width: "100%",
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
        height: "42px",
      }}
    >
      <Stepper activeStep={activeStep}>
        {progress.map((items, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>
                <p
                  style={{
                    fontSize: "0.5rem",
                  }}
                >
                  {items.goal.replaceAll("_", " ").toUpperCase()}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

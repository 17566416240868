import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const HeaderBox = styled(Box)`
  display: none;
  flex-direction: row;
  width: 97%;

  background-color: white;
  border-radius: 5px;

  margin-right: 1%;
  height: 40px;
  color: #65d191;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  padding-left: 2%;
  @media only screen and (min-width: 640px) {
    display: flex;
    height: fit-content;
    align-items: center;
  }
`;

export const BodyBox = styled(Box)`
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 97%;
  margin-right: 1%;
  min-height: 70px;
  color: #000000;
  justify-content: space-around;
  align-items: start;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  word-break: break-word;
  gap: 10px;
  padding-left: 2%;
  @media only screen and (min-width: 640px) {
    display: flex;
    height: fit-content;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
  }

  @media only screen and (max-width: 640px) {
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const AddressTypography = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 17px;
  height: fit-content;
  padding: 10px;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;

  @media only screen and (min-width: 640px) {
    font-size: 17px;
    width: 40%;
    padding: 10px;
    margin-left: 20px;
  }
`;

export const DamageTypeTypography = styled(Typography)`
  display: flex;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  font-size: 15px;
  height: fit-content;
  @media only screen and (min-width: 640px) {
    width: 20%;
    margin-right: 0%;
    margin-left: 0%;
    font-size: 15px;
    padding: 10px;
  }
`;

export const DamageDateTypography = styled(Typography)`
  height: fit-content;
  display: flex;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  font-size: 15px;
  @media only screen and (min-width: 640px) {
    width: 20%;
    margin-right: 0%;
    margin-left: 0%;
    font-size: 15px;
    padding: 10px;
  }
`;

export const DamageCreatedTypography = styled(Typography)`
  height: fit-content;
  display: flex;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  font-size: 15px;
  @media only screen and (min-width: 640px) {
    width: 20%;
    margin-right: 0%;
    margin-left: 0%;
    font-size: 15px;
    padding: 10px;
  }
`;

export const HeaderTypography = styled(Typography)`
height: fit-content;
display: flex;
width: 90%;
margin-right: 5%
margin-left: 5%;
font-size: 20px;
color: black;
margin: 0,
flex-wrap: wrap;
overflow: hidden;
padding: 10px;
${({ width }) => `
    @media only screen and (min-width: 640px) {
      width: ${width ? width : "20%"};
      margin-right: 0%;
      margin-left: 0%;
    }
  `}

`;

export const CardTypography = styled(Typography)`
height: fit-content;
display: flex;
width: 90%;
margin-right: 5%
margin-left: 5%;
font-size: 15px;
flex-wrap: wrap;
overflow: hidden;
padding: 10px;
${({ width, fontSize, color }) => `
font-size: ${fontSize ? fontSize : "15px"};
color: ${color ? color : "black"};
    @media only screen and (min-width: 640px) {
      width: ${width ? width : "20%"};
      margin-right: 0%;
      margin-left: 0%;
    }
  `}

`;

/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "./Login/Login";
import { NavBar } from "../components";

import { LogOut } from "./Login/Logout";
import { Profile } from "./Dashboard/Profile/Profile";
import React, { useEffect } from "react";
import { NotFoundPage } from "./NotFoundPage";
import { ResetPassword } from "./Login/ResetPassword";
import { MyProfile } from "./Dashboard/StafProfile/MyProfile";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userLogin } from "../redux/reducers";
import { Leads } from "./Dashboard/Leads";
import { Projects } from "./Dashboard/projects";
import { InsuranceCompanies } from "./Dashboard/InsuranceCompany";
import { ProjectDescription } from "./Dashboard/ProjectDetails";
import { Materials } from "./Dashboard/Materials";
import { Users } from "./Dashboard/Users/Users";
import { Waitlist } from "./Dashboard/Waitlist";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

import { MapPage } from "./MapPage";
import { AllowRoutesProps, PageType } from "../types/interface";


const availablePages: PageType[] = [
  {
    pageName: "leads",
    component: <Leads />,
  },
  {
    pageName: "profile",
    component: <Profile />,
  },
  {
    pageName: "my-profile",
    component: <MyProfile />,
  },

  {
    pageName: "logout",
    component: <LogOut />,
  },
];

const AllowedRoutes: React.FC<AllowRoutesProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      dispatch(userLogin({ token, _id: "" }));
    }
  }, []);

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  if (profile.token) {
    return (
      <div
        style={{
          width: "100%",

          height: "100%",
          backgroundColor: "#F3F3F3",

        }}
      >
        <Routes>
          <Route path="/dashboard" element={<Leads />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/self-reset-password" element={<ResetPassword />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/insurance" element={<InsuranceCompanies />} />
          <Route path="/projects/:id" element={<ProjectDescription />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/users" element={<Users />} />
          <Route path="/" element={<Leads />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path="/self-reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Login />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }
};

interface RouteManagerProps {}

const FullScreenLayout = () => {
  const location = useLocation();
  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const isLoginPage = profile.token;

  return (
    <Box
      display="flex"
      width="100vw"
      minHeight="100vh"
      flexDirection="row"
      alignItems="stretch"
      style={{ backgroundColor: "#F3F3F3" }}
    >
      {isLoginPage && (
        <Box
          sx={{
            backgroundColor: "black",
            width: "75px",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              width: "75px",
              height: "100%",
            }}
          >
            <NavBar />
          </Box>
        </Box>
      )}

      <Box
        sx={{

          height: "100%",
          overflow: "hidden",

          width: profile.token
            ? "calc(100% - 60px - 40px)"
            : "calc(100% - 40px)",
        }}
      >
        <AllowedRoutes />
      </Box>
    </Box>
  );
};

export const RouteManager: React.FC<RouteManagerProps> = ({}) => {
  return (
    <BrowserRouter>
      <FullScreenLayout />
    </BrowserRouter>
  );
};

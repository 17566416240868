import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BaseImage = styled.div`
  /* Layout Properties */
  top: 363px;
  left: 845px;
  width: 442px;
  height: 526px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  /* UI Properties */
  background: transparent url("../../images/Base.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
`;

export const CategoryList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 98%;
  padding: 1%;
  overflow-x: scroll;
`;

export const DashboardIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SearchIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const SearchInputBox = styled(Box)`
  width: 100%;
  height: 35px;
  @media only screen and (min-width: 480px) {
    width: 342px;
  }
`;

export const PreviousIcon = styled.img`
  width: 8px;
  height: 12px;
`;

export const RowIconDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

import { Estimate, EstimateAddOnsInput } from "../types/Estimate";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";

export const GetEstimate = async (projectId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/project/${projectId}/estimate`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const SaveEstimate = async (input: Estimate) => {
  console.log(input);

  try {
    const api = axiosAuth();
    const response = await api.put(`${BASE_URL}/project/estimate`, input);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const CreateEstimateAddOns = async (input: EstimateAddOnsInput) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${BASE_URL}/project/estimate-addons`, {
      ...input,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetEstimateAddOns = async (pId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(
      `${BASE_URL}/project/${pId}/estimate-addons`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const DeleteEstimateAddOns = async (id: number) => {
  try {
    const api = axiosAuth();
    const response = await api.delete(
      `${BASE_URL}/project/estimate-addons/${id}`
    );
    console.log(response);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GenerateInvoice = async (id: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/gen/invoice/${id}`);
    console.log(response);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const RegenerateEstimate = async (pId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/project/estimate/gen/${pId}`);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const UpdateSignedDocs = async (pId: number, docType: string) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(
      `${BASE_URL}/project/${pId}/update-signed-docs/${docType}`
    );
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

import { FC, useState } from "react";
import {
  AppCSS,
  ScopeTxtInput,
  Spacer,
  TapButton,
} from "../../../../components";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";
import { Docs, Progress, Project } from "../../../../redux/types";
import { FormatString } from "../../../../utils";
import moment from "moment";
import { toast } from "react-toastify";
import { ApprovePayment, RejectPayment } from "../../../../redux/api/insurance";
import { TinyLbl } from "../../../../components/labels/labels.styled";

interface PaymentProps {
  onCompleted: Function;
  project?: Project;
  docs?: Docs[];
  progress?: Progress[];
}

export const PaymentDetails: FC<PaymentProps> = ({
  docs,
  project,
  progress,
  onCompleted,
}) => {
  const [isApprove, setIsApprove] = useState(false);
  const [amount, setAmount] = useState("");

  const onTapApprove = async (doc: Docs) => {
    if (+amount < 1) {
      toast("Please enter Check amount!", {
        type: "error",
      });
      return;
    }
    try {
      const status = await ApprovePayment(project.id, {
        payment_type: doc.meta_data,
        amount: +amount,
        doc_id: doc.id,
        status: "approved",
      });
      if (status === 200) {
        // show alert
        toast("Payment is approved!", {
          type: "success",
        });
        onCompleted();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapRejectPayment = async (doc: Docs) => {
    try {
      const status = await ApprovePayment(project.id, {
        payment_type: doc.meta_data,
        amount: 0,
        doc_id: doc.id,
        status: "reject",
      });
      if (status === 200) {
        // show alert
        toast("Payment Rejected!", {
          type: "success",
        });
        onCompleted();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const progressStatus = (goal: string) => {
    if (Array.isArray(progress)) {
      const currentStatus = progress.find(
        (p) => p.goal.toLowerCase() === goal.toLowerCase()
      );
      console.log(currentStatus);
      return currentStatus?.completed && currentStatus.approved;
    }

    return false;
  };

  const paymentApproval = (doc: Docs) => {
    if (isApprove) {
      return (
        <RowDiv style={{ alignItems: "flex-end" }} key={doc.id}>
          <ColDiv style={{ alignItems: "flex-end", marginRight: 20 }}>
            <TinyLbl>Received Amount</TinyLbl>
            <ScopeTxtInput
              width={120}
              value={amount}
              placeholder="amount"
              onChange={setAmount}
              unit="$"
            />
          </ColDiv>
          <TapButton
            title="Cancel"
            onTap={() => {
              setAmount("0");
              setIsApprove(false);
            }}
            bgColor={AppCSS.RED}
            height={38}
            width={120}
          />
          <TapButton
            title="Approve"
            onTap={() => onTapApprove(doc)}
            bgColor={AppCSS.GLAS_BLUE}
            height={38}
            width={120}
          />
        </RowDiv>
      );
    } else {
      if (!progressStatus(doc.meta_data)) {
        return (
          <div
            key={doc.id}
            style={{
              display: "flex",
              width: "220",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <TapButton
              title="Reject"
              onTap={() => onTapRejectPayment(doc)}
              bgColor={AppCSS.RED}
              height={38}
              width={120}
            />
            <TapButton
              title="Approve"
              onTap={() => setIsApprove(true)}
              bgColor={AppCSS.GLAS_BLUE}
              height={38}
              width={120}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              width: "220",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <p style={{ color: AppCSS.GLAS_BLUE, fontWeight: "600" }}>
              Approved
            </p>
          </div>
        );
      }
    }
  };

  return (
    <>
      <ColDiv
        style={{
          width: "100%",
        }}
      >
        <table style={{ width: "100%" }}>
          <tr
            style={{
              width: "100%",
              height: "40px",
              background: AppCSS.GLAS_BLUE,
            }}
          >
            <th style={{ color: "white", borderRadius: 10, width: "25%" }}>
              Payment Date
            </th>
            <th style={{ color: "white", borderRadius: 10, width: "25%" }}>
              Payment Type
            </th>
            <th style={{ color: "white", borderRadius: 10, width: "25%" }}>
              Payment Amount
            </th>
            <th style={{ color: "white", borderRadius: 10, width: "25%" }}>
              Payment Receipt
            </th>
          </tr>
          {Array.isArray(docs) &&
            docs
              .filter((item) => item.file_type.toLowerCase() === "payment")
              .map((item, index) => (
                <tr key={index} style={{ width: "100%", height: "40px" }}>
                  <td
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                      maxWidth: "50%",
                    }}
                  >
                    <p>{moment(item.created_at).format("LL")}</p>
                  </td>
                  <td
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                      maxWidth: "50%",
                    }}
                  >
                    <p>{FormatString(item.meta_data)}</p>
                  </td>
                  <td
                    style={{
                      paddingLeft: 5,
                      textAlign: "center",
                      maxWidth: "50%",
                    }}
                  >
                    <a
                      href={`${item.file_url}`}
                      target="_blank"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 15,
                        color: AppCSS.GLAS_BLUE,
                      }}
                    >
                      <p>View File</p>
                    </a>
                  </td>
                  <td
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                      maxWidth: "50%",
                    }}
                  >
                    {paymentApproval(item)}
                  </td>
                </tr>
              ))}
        </table>
      </ColDiv>
    </>
  );
};

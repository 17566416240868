/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  CloseButton,
  ButtonWithIcon,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { CLIENT_URL } from "../../../utils";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColor";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { LocationBadge } from "../../../components/misc/LocationBagde";
import { useAppSelector } from "../../../redux/hooks";
import { WaitlistTable } from "./WaitlistTable";
import { GetLeads, GetWaitlist } from "../../../redux/api";
import { setWaitlist } from "../../../redux/reducers";
import { LeadModel, WaitlistModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import AppLoader from "../../../components/loader";

interface DashboardProps {}

export const Waitlist: React.FC<DashboardProps> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { waitlist } = userReducer;

  useEffect(() => {
    onFetchLeads()
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching leads:", error);
        setIsLoading(false);
      });
  }, []);

  const onFetchLeads = async () => {
    try {
      const data = await GetWaitlist();
      const userData = data as {
        message: string;
        data: { count: number; leads: WaitlistModel[] };
      };
      if (userData.data) {
        const { leads } = userData.data;
        dispatch(setWaitlist(leads));
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <WaitlistTable waitlist={waitlist} />
      </Container>
    );
  };

  return hostContainer();
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ErrorHandler: React.FC = () => {
  const dispatch = useDispatch();

  // get error
  //  const profile = useAppSelector((state) => state.userReducer).userProfile;

  useEffect(() => {
    // if (error?.code) {
    //   toast(`${error.message}`, {
    //     type: "error",
    //   });
    //   setTimeout(() => {
    //     dispatch(onDismissError());
    //   }, 2000);
    // }
  }, [dispatch]);

  return <></>;
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch } from "react-redux";
import { CenterBox, ColDiv, RowDiv } from "../../components/misc/misc.styled";
import { Lbl } from "../../components/labels";
import { AppCSS, Spacer, TapButton, TxtInput } from "../../components";
import { useEffect, useState } from "react";
import { LoginContainer } from "./login.styled";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetToken, setResetToken] = useState("");

  useEffect(() => {
    const token = window.location.href.split("=")[1];
    setResetToken(token);
  }, []);

  const onTapSubmit = () => {
    if (password.length > 5 && confirmPassword.length > 5) {
      if (password === confirmPassword) {
        if (resetToken === "") {
          toast("Password can not be change! Your token is expired!", {
            type: "error",
          });

          return;
        }
        // dispatch(onShowLoader());
        // dispatch(onChangePassword(resetToken, password));
        return;
      } else {
        toast("Password can not be change! Your token is expired!", {
          type: "success",
        });
        toast("Confirm password does not match!", {
          type: "error",
        });
        return;
      }
    }

    toast(
      "Please provide password & Confirm Password! it must be at least 6 characters long.",
      {
        type: "error",
      }
    );
  };

  /*
  if (resetToken) {
    if (passwordSent) {
      return (
        <LoginContainer>
          <CenterBox
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spacer size={4} direction="col" />

            <Lbl
              title="Password has changed successfully!"
              size={16}
              align="center"
            />

            <Spacer size={2} direction="col" />
            <TapButton
              width={320}
              onTap={() => navigate("/logout")}
              title="Continue to Login"
              bgColor={AppCSS.RED}
            />
            <Spacer size={1} direction="col" />
          </CenterBox>
        </LoginContainer>
      );
    } else {
      return (
        <LoginContainer>
          <CenterBox
            style={{
              maxWidth: "420px",
            }}
          >
            <RowDiv>
              <Lbl title="Reset Password" size={20} bold={600} />
            </RowDiv>
            <Spacer size={3} direction="col" />
            <ColDiv>
              <TxtInput
                type="password"
                placeholder="Password"
                onChange={setPassword}
                onPressEnter={() => {
                  onTapSubmit();
                }}
              />
              <Spacer size={1} direction="col" />
              <TxtInput
                type="password"
                placeholder="Confirm Password"
                onChange={setConfirmPassword}
                onPressEnter={() => {
                  onTapSubmit();
                }}
              />
            </ColDiv>
            <Spacer size={3} direction="col" />

            <ColDiv
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TapButton
                width={320}
                onTap={onTapSubmit}
                title="Submit"
                bgColor={AppCSS.RED}
              />
            </ColDiv>
            <Spacer size={1} direction="col" />
          </CenterBox>
        </LoginContainer>
      );
    }
  } else { */
  return (
    <LoginContainer>
      <CenterBox
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spacer size={4} direction="col" />

        <Lbl
          title="Password can not change without reset token!"
          size={16}
          align="center"
        />

        <Spacer size={1} direction="col" />
      </CenterBox>
    </LoginContainer>
  );
};

import { Container } from "@mui/material";
import { AddMaterial } from "./AddMaterial";
import { MaterialTable } from "./MaterialTable";
import { FC, useState } from "react";
import { Material } from "../../../../redux/types/Material";

interface RoofMatProps {
  onDone: Function;
  materials?: Material[];
}

export const RoofMaterials: FC<RoofMatProps> = ({ onDone, materials }) => {
  const [addMaterial, setAddMaterial] = useState(false);

  const onDisplayAddMaterial = () => {
    setAddMaterial(!addMaterial);
  };

  if (addMaterial) {
    return (
      <Container>
        <AddMaterial
          matType="roof"
          onCancel={() => setAddMaterial(false)}
          onTapAdd={() => {
            setAddMaterial(false);
            onDone("roof");
          }}
        />
      </Container>
    );
  } else {
    return (
      <MaterialTable
        materials={Array.isArray(materials) ? materials : []}
        onTapAddCompany={() => onDisplayAddMaterial()}
      />
    );
  }
};

import React from "react";
import { HeaderBox, CardTypography } from "./table.styled";

interface Header {
  text: string;
  width: string;

  justifyContent?: string;

}

interface HeaderCardProps {
  headers: Header[];
}

const HeaderCard: React.FC<HeaderCardProps> = ({ headers }) => {
  return (
    <HeaderBox>
      {headers.map((header, index) => (
        <CardTypography
          key={index}
          width={header.width}

          fontSize="18px"

          color="black"
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",

            justifyContent: header.justifyContent ? header.justifyContent : "",

          }}
          variant="body1"
        >
          {header.text}
        </CardTypography>
      ))}
    </HeaderBox>
  );
};

export default HeaderCard;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { FC, memo, useEffect, useState } from "react";
import {
  AppCSS,
  Lbl,
  ScopeTxtInput,
  Spacer,
  TapButton,
  TxtInput,
} from "../../../../components";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TinyLbl } from "../../../../components/labels/labels.styled";
import { Docs, Project } from "../../../../redux/types";
import { ApproveInsuranceScope } from "../../../../redux/api/insurance";
import { toast } from "react-toastify";
import { Estimate, EstimateAddOns } from "../../../../redux/types/Estimate";
import { SaveEstimate } from "../../../../redux/api/estimate";
import { Prompt } from "../../../../components/dialogue/Dialogue";
import { ProjectAddOns } from "../../../../components/ProjectAddOns";
import { get } from "lodash";

interface InsuranceProps {
  estimate?: Estimate;
  estimateAddOns?: EstimateAddOns[];
  project?: Project;
  docs?: Docs[];
  onFetchEstimate: Function;
}

export const InsuranceDetails: FC<InsuranceProps> = ({
  project,
  estimate,
  estimateAddOns,
  onFetchEstimate,
  docs,
}) => {
  const [shingles, setShingles] = useState(0);
  const [starter, setStarter] = useState(0);
  const [ridge_flex, setRidge_flex] = useState(0);
  const [dip_edge, setDipEdge] = useState(0);
  const [felt, setFelt] = useState(0);
  const [ice_n_water_shield, setIce_n_water_shield] = useState(0);
  const [sealant, setSealant] = useState(0);
  const [roofing_nails, setRoofingNails] = useState(0);
  const [cap_nails, setCapNails] = useState(0);
  const [static_vent, setStaticVent] = useState(0);
  const [powered_vent, setPoweredVent] = useState(0);
  const [box_vent, setBoxVent] = useState(0);
  const [wind_turbine_vent, setWindTurbineVent] = useState(0);
  const [dome_vent, setDomeVent] = useState(0);
  const [solar_attic_fan, setSolarAtticFan] = useState(0);
  const [ridge_vent, setRidgeVent] = useState(0);
  const [gutters, setGutters] = useState(0);
  const [high_profile_ridge, setHigh_profile_ridge] = useState(0);
  const [osb, setOsb] = useState(0);
  const [lead_pipe_flashing_one_one_two, setLeadPipeFlashingOneOneTwo] =
    useState(0);
  const [lead_pipe_flashing_two, setLeadPipeFlashingTwo] = useState(0);
  const [lead_pipe_flashing_three, setLeadPipeFlashingThree] = useState(0);
  const [galvanized_counter_flashing_six, setGalvanized_counter_flashing_six] =
    useState(0);
  const [
    galvanized_counter_flashing_twenty,
    setGalvanized_counter_flashing_twenty,
  ] = useState(0);
  const [textured_metal_edge_one_one_two, setTextured_metal_edge_one_one_two] =
    useState(0);
  const [metal_edge_two_two, setMetalEdge_two_two] = useState(0);
  const [metal_edge_two_four, setMetalEdge_two_four] = useState(0);
  const [metal_edge_four_four, setMetalEdge_four_four] = useState(0);
  const [gutter_screens, setGutter_screens] = useState(0);

  const [one_twelve_to_seven_twelve, setOne12To712] = useState(0);
  const [eight_twelve, set812] = useState(0);
  const [nine_twelve, set912] = useState(0);
  const [ten_twelve, set1012] = useState(0);
  const [eleven_twelve, set1112] = useState(0);
  const [twelve_twelve, set1212] = useState(0);
  const [thirteen_twelve, set1312] = useState(0);
  const [fourteen_twelve, set1412] = useState(0);
  const [fifteen_twelve, set1512] = useState(0);
  const [sixteen_twelve, set1612] = useState(0);
  const [seventeen_twelve, set1712] = useState(0);
  const [eighteen_twelve, set1812] = useState(0);
  const [nineteen_twelve, set1912] = useState(0);
  const [twenty_twelve, set2012] = useState(0);
  const [twenty_one_twelve, set2112] = useState(0);
  const [twenty_two_twelve, set2212] = useState(0);
  const [twenty_three_twelve, set2312] = useState(0);
  const [twenty_four_twelve, set2412] = useState(0);
  const [two_story, setTwo_story] = useState(0);
  const [three_story, setThree_story] = useState(0);
  const [four_story, setFour_story] = useState(0);

  const [insurance_payout, setInsurancePayout] = useState(0);
  const [first_check, setFirstCheck] = useState(0);
  const [second_check, setSecondCheck] = useState(0);
  const [deductible, setDeductible] = useState(0);
  const [glas_discount, setGlas_discount] = useState(0);

  const [permit_fee, setPermitFee] = useState(0);
  const [insurance_claim_number, setInsuranceClaimNumber] = useState("");
  const [insurance_claim_date, setInsuranceClaimDate] = useState("");
  const [insurer, setInsurer] = useState("");

  const [line_items, setLine_items] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (estimate) {
      const e = estimate;
      setShingles(e.shingles);
      setStarter(e.starter);
      setRidge_flex(e.ridge_flex);
      setDipEdge(e.dip_edge);
      setFelt(e.felt);
      setIce_n_water_shield(e.ice_n_water_shield);
      setSealant(e.sealant);
      setRoofingNails(e.roofing_nails);
      setCapNails(e.cap_nails);
      setStaticVent(e.static_vent);
      setPoweredVent(e.powered_vent);
      setBoxVent(e.box_vent);
      setWindTurbineVent(e.wind_turbine_vent);
      setDomeVent(e.dome_vent);
      setSolarAtticFan(e.solar_attic_fan);
      setRidgeVent(e.ridge_vent);
      setGutters(e.gutters);
      setHigh_profile_ridge(e.high_profile_ridge);
      setOsb(e.osb);
      setLeadPipeFlashingOneOneTwo(e.lead_pipe_flashing_one_one_two);
      setLeadPipeFlashingTwo(e.lead_pipe_flashing_two);
      setLeadPipeFlashingThree(e.lead_pipe_flashing_three);
      setGalvanized_counter_flashing_six(e.galvanized_counter_flashing_six);
      setGalvanized_counter_flashing_twenty(
        e.galvanized_counter_flashing_twenty
      );
      setTextured_metal_edge_one_one_two(e.textured_metal_edge_one_one_two);
      setMetalEdge_two_two(e.metal_edge_two_two);
      setMetalEdge_two_four(e.metal_edge_two_four);
      setMetalEdge_four_four(e.metal_edge_four_four);
      setGutter_screens(e.gutter_screens);
      setOne12To712(e.one_twelve_to_seven_twelve);
      set812(e.eight_twelve);
      set912(e.nine_twelve);
      set1012(e.ten_twelve);
      set1112(e.eleven_twelve);
      set1212(e.twelve_twelve);
      set1312(e.thirteen_twelve);
      set1412(e.fourteen_twelve);
      set1512(e.fifteen_twelve);
      set1612(e.sixteen_twelve);
      set1712(e.seventeen_twelve);
      set1812(e.eighteen_twelve);
      set1912(e.nineteen_twelve);
      set2012(e.twenty_twelve);
      set2112(e.twenty_one_twelve);
      set2212(e.twenty_two_twelve);
      set2312(e.twenty_three_twelve);
      set2412(e.twenty_four_twelve);
      setTwo_story(e.two_story);
      setThree_story(e.three_story);
      setFour_story(e.four_story);
      setInsurancePayout(e.insurance_payout);
      setFirstCheck(e.first_check);
      setSecondCheck(e.second_check);
      setDeductible(e.deductible);
      setGlas_discount(e.glas_discount);
      setPermitFee(e.permit_fee);
      setInsuranceClaimNumber(e.insurance_claim_number);
      setInsuranceClaimDate(e.insurance_claim_date);
      setInsurer(e.insurer);
      setLine_items(e.line_items);
    }
  }, [estimate]);

  const OnSaveData = async () => {
    try {
      const e = estimate;
      const input: Estimate = {
        ...e,
        shingles: +shingles,
        starter: +starter,
        ridge_flex: +ridge_flex,
        dip_edge: +dip_edge,
        felt: +felt,
        ice_n_water_shield: +ice_n_water_shield,
        sealant: +sealant,
        roofing_nails: +roofing_nails,
        cap_nails: +cap_nails,
        static_vent: +static_vent,
        powered_vent: +powered_vent,
        box_vent: +box_vent,
        wind_turbine_vent: +wind_turbine_vent,
        dome_vent: +dome_vent,
        solar_attic_fan: +solar_attic_fan,
        ridge_vent: +ridge_vent,
        gutters: +gutters,
        high_profile_ridge: +high_profile_ridge,
        osb: +osb,
        lead_pipe_flashing_one_one_two: +lead_pipe_flashing_one_one_two,
        lead_pipe_flashing_two: +lead_pipe_flashing_two,
        lead_pipe_flashing_three: +lead_pipe_flashing_three,
        galvanized_counter_flashing_six: +galvanized_counter_flashing_six,
        galvanized_counter_flashing_twenty: +galvanized_counter_flashing_twenty,
        textured_metal_edge_one_one_two: +textured_metal_edge_one_one_two,
        metal_edge_two_two: +metal_edge_two_two,
        metal_edge_two_four: +metal_edge_two_four,
        metal_edge_four_four: +metal_edge_four_four,
        gutter_screens: +gutter_screens,
        one_twelve_to_seven_twelve: +one_twelve_to_seven_twelve,
        eight_twelve: +eight_twelve,
        nine_twelve: +nine_twelve,
        ten_twelve: +ten_twelve,
        eleven_twelve: +eleven_twelve,
        twelve_twelve: +twelve_twelve,
        thirteen_twelve: +thirteen_twelve,
        fourteen_twelve: +fourteen_twelve,
        fifteen_twelve: +fifteen_twelve,
        sixteen_twelve: +sixteen_twelve,
        seventeen_twelve: +seventeen_twelve,
        eighteen_twelve: +eighteen_twelve,
        nineteen_twelve: +nineteen_twelve,
        twenty_twelve: +twenty_twelve,
        twenty_one_twelve: +twenty_one_twelve,
        twenty_two_twelve: +twenty_two_twelve,
        twenty_three_twelve: +twenty_three_twelve,
        twenty_four_twelve: +twenty_four_twelve,
        two_story: +two_story,
        three_story: +three_story,
        four_story: +four_story,
        insurance_payout: +insurance_payout,
        first_check: +first_check,
        second_check: +second_check,
        deductible: +deductible,
        glas_discount: +glas_discount,
        permit_fee: +permit_fee,
        insurance_claim_number: insurance_claim_number,
        insurance_claim_date: insurance_claim_date,
        insurer: insurer,
        line_items: line_items,
      };

      const res = await SaveEstimate(input);
      if (res === 200) {
        toast("Updated successfully", {
          type: "success",
        });
      } else {
        toast("Failed to save Data", {
          type: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LineItems = () => {
    return (
      <>
        <div
          style={{
            borderBottom: `2px solid ${AppCSS.GLAS_BLUE}`,
            paddingBottom: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              margin: 0,
              overflow: "auto",
            }}
          >
            Material Measurements
          </p>
        </div>

        {/* line 1 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Squares</TinyLbl>
            <ScopeTxtInput
              value={shingles}
              placeholder="Area of Roof 20% Waste"
              onChange={setShingles}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Starter</TinyLbl>
            <ScopeTxtInput
              value={starter}
              placeholder="Perimeter"
              onChange={setStarter}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Hip & Ridge</TinyLbl>
            <ScopeTxtInput
              value={ridge_flex}
              placeholder="Hips + Ridges"
              onChange={setRidge_flex}
              unit="LF"
            />
          </ColDiv>
        </RowDiv>

        {/* line 2 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Drip Edge</TinyLbl>
            <ScopeTxtInput
              value={dip_edge}
              placeholder="Eaves + Rakes"
              onChange={setDipEdge}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Felt</TinyLbl>
            <ScopeTxtInput
              value={felt}
              placeholder="Area of Roof"
              onChange={setFelt}
              unit="SF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Ice & Water</TinyLbl>
            <ScopeTxtInput
              value={ice_n_water_shield}
              placeholder="Valleys"
              onChange={setIce_n_water_shield}
              unit="SF"
            />
          </ColDiv>
        </RowDiv>

        {/* line 3 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Polyurethane Sealant</TinyLbl>
            <ScopeTxtInput
              value={sealant}
              placeholder="Area of Roof"
              onChange={setSealant}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Roofing Nails</TinyLbl>
            <ScopeTxtInput
              value={roofing_nails}
              placeholder="Area of Roof"
              onChange={setRoofingNails}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Cap Nails</TinyLbl>
            <ScopeTxtInput
              value={cap_nails}
              placeholder="Area of Roof"
              onChange={setCapNails}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>

        {/* line 4 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Static Vent</TinyLbl>
            <ScopeTxtInput
              value={static_vent}
              placeholder="Scope"
              onChange={setStaticVent}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Powered Fan</TinyLbl>
            <ScopeTxtInput
              value={powered_vent}
              placeholder="Scope"
              onChange={setPoweredVent}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Box vent</TinyLbl>
            <ScopeTxtInput
              value={box_vent}
              placeholder="Scope"
              onChange={setBoxVent}
              unit="EA"
            />
          </ColDiv>
        </RowDiv>

        {/* line 5 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Wind Turbine Vent</TinyLbl>
            <ScopeTxtInput
              value={wind_turbine_vent}
              placeholder="Scope"
              onChange={setWindTurbineVent}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Dome Vent</TinyLbl>
            <ScopeTxtInput
              value={dome_vent}
              placeholder="Scope"
              onChange={setDomeVent}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Solar Attic Fan</TinyLbl>
            <ScopeTxtInput
              value={solar_attic_fan}
              placeholder="Scope"
              onChange={setSolarAtticFan}
              unit="EA"
            />
          </ColDiv>
        </RowDiv>

        {/* line 6 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Ridge Vent</TinyLbl>
            <ScopeTxtInput
              value={ridge_vent}
              placeholder="Ridge Length"
              onChange={setRidgeVent}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Gutters</TinyLbl>
            <ScopeTxtInput
              value={gutters}
              placeholder="Scope"
              onChange={setGutters}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Hip & Ridge - High Profile</TinyLbl>
            <ScopeTxtInput
              value={high_profile_ridge}
              placeholder="Scope"
              onChange={setHigh_profile_ridge}
              unit="LF"
            />
          </ColDiv>
        </RowDiv>

        {/* line 7 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>OSB</TinyLbl>
            <ScopeTxtInput
              value={osb}
              placeholder="scope"
              onChange={setOsb}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>LEAD PIPE FLASHING 1-1/2"</TinyLbl>
            <ScopeTxtInput
              value={lead_pipe_flashing_one_one_two}
              placeholder="Scope"
              onChange={setLeadPipeFlashingOneOneTwo}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>LEAD PIPE FLASHING 2"</TinyLbl>
            <ScopeTxtInput
              value={lead_pipe_flashing_two}
              placeholder="Scope"
              onChange={setLeadPipeFlashingTwo}
              unit="EA"
            />
          </ColDiv>
        </RowDiv>

        {/* line 8 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>LEAD PIPE FLASHING 3"</TinyLbl>
            <ScopeTxtInput
              value={lead_pipe_flashing_three}
              placeholder="scope"
              onChange={setLeadPipeFlashingThree}
              unit="EA"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>GAL. COUNTER FLASHING 6"</TinyLbl>
            <ScopeTxtInput
              value={galvanized_counter_flashing_six}
              placeholder="Scope"
              onChange={setGalvanized_counter_flashing_six}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>GAL. ROLL FLASHING 20"</TinyLbl>
            <ScopeTxtInput
              value={galvanized_counter_flashing_twenty}
              placeholder="Scope"
              onChange={setGalvanized_counter_flashing_twenty}
              unit="LF"
            />
          </ColDiv>
        </RowDiv>

        {/* line 9 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>TEXTURED METAL EDGE 1-1/2"</TinyLbl>
            <ScopeTxtInput
              value={textured_metal_edge_one_one_two}
              placeholder="scope"
              onChange={setTextured_metal_edge_one_one_two}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>METAL EDGE 2"X 2"</TinyLbl>

            <ScopeTxtInput
              value={metal_edge_two_two}
              placeholder="Scope"
              onChange={setMetalEdge_two_two}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>METAL EDGE 2"X 4"</TinyLbl>
            <ScopeTxtInput
              value={metal_edge_two_four}
              placeholder="Scope"
              onChange={setMetalEdge_two_four}
              unit="LF"
            />
          </ColDiv>
        </RowDiv>

        {/* line 10 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>METAL EDGE 4"X 4"</TinyLbl>
            <ScopeTxtInput
              value={metal_edge_four_four}
              placeholder="scope"
              onChange={setMetalEdge_four_four}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>GUTTER SCREENS"</TinyLbl>
            <ScopeTxtInput
              value={gutter_screens}
              placeholder="Scope"
              onChange={setGutter_screens}
              unit="LF"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv></ColDiv>
        </RowDiv>
      </>
    );
  };

  const LaborItems = () => {
    return (
      <>
        <div
          style={{
            borderBottom: `2px solid ${AppCSS.GLAS_BLUE}`,
            paddingBottom: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Labor Quantities
          </p>
        </div>
        {/* line 1 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl> 1/12 to 7/12</TinyLbl>
            <ScopeTxtInput
              value={one_twelve_to_seven_twelve}
              placeholder="Roof Pitch Squares"
              onChange={setOne12To712}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>8/12</TinyLbl>
            <ScopeTxtInput
              value={eight_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set812}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>9/12</TinyLbl>
            <ScopeTxtInput
              value={nine_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set912}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>

        {/* line 2 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>10/12</TinyLbl>
            <ScopeTxtInput
              value={ten_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1012}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>11/12</TinyLbl>
            <ScopeTxtInput
              value={eleven_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1112}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>12/12</TinyLbl>
            <ScopeTxtInput
              value={twelve_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1212}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>

        {/* line 3 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>13/12</TinyLbl>
            <ScopeTxtInput
              value={thirteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1312}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>14/12</TinyLbl>
            <ScopeTxtInput
              value={fourteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1412}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>15/12</TinyLbl>
            <ScopeTxtInput
              value={fifteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1512}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>

        {/* line 4 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>16/12</TinyLbl>
            <ScopeTxtInput
              value={sixteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1612}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>17/12</TinyLbl>
            <ScopeTxtInput
              value={seventeen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1712}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>18/12</TinyLbl>
            <ScopeTxtInput
              value={eighteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1812}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>

        {/* line 5 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>19/12</TinyLbl>
            <ScopeTxtInput
              value={nineteen_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set1912}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>20/12</TinyLbl>
            <ScopeTxtInput
              value={twenty_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set2012}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>21/12</TinyLbl>
            <ScopeTxtInput
              value={twenty_one_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set2112}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>
        {/* line 6 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>22/12</TinyLbl>
            <ScopeTxtInput
              value={twenty_two_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set2212}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>23/12</TinyLbl>
            <ScopeTxtInput
              value={twenty_three_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set2312}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>24/12</TinyLbl>
            <ScopeTxtInput
              value={twenty_four_twelve}
              placeholder="Roof Pitch Squares"
              onChange={set2412}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>
        {/* line 7 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Two Story</TinyLbl>
            <ScopeTxtInput
              value={two_story}
              placeholder="Squares"
              onChange={setTwo_story}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Three Story</TinyLbl>
            <ScopeTxtInput
              value={three_story}
              placeholder="Squares"
              onChange={setThree_story}
              unit="SQ"
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Four Story</TinyLbl>
            <ScopeTxtInput
              value={four_story}
              placeholder="Squares"
              onChange={setFour_story}
              unit="SQ"
            />
          </ColDiv>
        </RowDiv>
      </>
    );
  };

  const PricingLineItems = () => {
    return (
      <>
        <div
          style={{
            borderBottom: `2px solid ${AppCSS.GLAS_BLUE}`,
            paddingBottom: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Payment Details
          </p>
        </div>
        {/* line 1 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Insurance Payout</TinyLbl>
            <TxtInput
              value={insurance_payout}
              placeholder="Scope"
              onChange={setInsurancePayout}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>First Check</TinyLbl>
            <TxtInput
              value={first_check}
              placeholder="Scope"
              onChange={setFirstCheck}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Second Check</TinyLbl>
            <TxtInput
              value={second_check}
              placeholder="Scope"
              onChange={setSecondCheck}
            />
          </ColDiv>
        </RowDiv>

        {/* line 2 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Deductible</TinyLbl>
            <TxtInput
              value={deductible}
              placeholder="Scope"
              onChange={setDeductible}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Glass Fee</TinyLbl>
            <TxtInput
              value={glas_discount}
              placeholder="Scope"
              onChange={setGlas_discount}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />

          <ColDiv>
            <TinyLbl>Permit Fee</TinyLbl>
            <TxtInput
              value={permit_fee}
              placeholder="Permit Fee"
              onChange={setPermitFee}
            />
          </ColDiv>
        </RowDiv>

        {/* line 3 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Line Items</TinyLbl>
            <TxtInput
              value={line_items}
              placeholder="Line items being completed from scope"
              onChange={setLine_items}
            />
          </ColDiv>
        </RowDiv>
      </>
    );
  };

  const InsuranceClaimView = () => {
    return (
      <>
        <div
          style={{
            borderBottom: `2px solid ${AppCSS.GLAS_BLUE}`,
            paddingBottom: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Insurance Claim
          </p>
        </div>
        {/* line 1 */}
        <RowDiv
          style={{
            width: "100%",
            height: 80,
          }}
        >
          <ColDiv>
            <TinyLbl>Insurer</TinyLbl>
            <TxtInput
              value={insurer}
              placeholder="Insurer"
              onChange={setInsurer}
            />
          </ColDiv>
          <ColDiv>
            <TinyLbl>Insurance Claim Number</TinyLbl>
            <TxtInput
              value={insurance_claim_number}
              placeholder="Claim Number"
              onChange={setInsuranceClaimNumber}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <TinyLbl>Insurance Claim Date</TinyLbl>
            <TxtInput
              value={insurance_claim_date}
              placeholder="YYYY-MM-DD"
              onChange={setInsuranceClaimDate}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
        </RowDiv>
      </>
    );
  };

  const getPDF = () => {
    let scopeUrl = "";
    if (Array.isArray(docs)) {
      docs.forEach((item) => {
        if (item.file_type === "insurance scope") {
          scopeUrl = item.file_url;
        }
      });
    }
    return scopeUrl;
  };

  const onTapApproveScope = async () => {
    setOpenConfirm(false);
    try {
      const status = await ApproveInsuranceScope(project.id);
      if (status === 200) {
        // show alert
        toast("Scope approved!", {
          type: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Prompt
        message="Approve Insurance scope?"
        onConfirm={() => {
          OnSaveData();
          onTapApproveScope();
        }}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",

          padding: 10,
        }}
      >
        <div
          style={{
            flex: 5,
          }}
        >
          <object
            data={getPDF()}
            type="application/pdf"
            width="450px"
            style={{ height: "100vh" }}
            onError={(e) => {
              console.log(e);
            }}
          >
            Insurance Scope
          </object>
          {getPDF().length > 0 && (
            <li style={{ listStyle: "none", padding: 10 }}>
              <a href={`${getPDF()}`} target="__blank">
                View Original File
              </a>
            </li>
          )}
        </div>
        {/* right side */}
        <div
          style={{
            display: "flex",
            flex: 9,
            justifyContent: "flex-start",
            flexDirection: "column",
            height: "100vh",
            marginLeft: 20,
            overflowY: "auto",
          }}
        >
          {LineItems()}
          <Spacer direction="col" size={2} />
          {LaborItems()}
          <Spacer direction="col" size={2} />
          {PricingLineItems()}
          <Spacer direction="col" size={2} />
          {InsuranceClaimView()}
          <Spacer direction="col" size={1} />
          <ProjectAddOns
            title="AddOns"
            AddOnType="scope"
            project={project}
            estimateAddOns={estimateAddOns}
          />
          <Spacer direction="col" size={4} />

          <Spacer direction="col" size={2} />
        </div>
      </div>
      <Spacer direction="col" size={3} />
      <RowDiv style={{ justifyContent: "flex-end" }}>
        <TapButton
          title="Approve Scope"
          onTap={() => {
            setOpenConfirm(true);
          }}
          bgColor={AppCSS.WHITE}
          color={AppCSS.GLAS_BLUE}
          borderColor={AppCSS.GLAS_BLUE}
          width={200}
          height={38}
        />
        <Spacer direction="row" size={2} />
        <TapButton
          title="Save scope data"
          onTap={() => OnSaveData()}
          bgColor={AppCSS.GLAS_BLUE}
          width={200}
          height={38}
        />
      </RowDiv>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  CloseButton,
  ButtonWithIcon,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { CLIENT_URL } from "../../../utils";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColor";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { LocationBadge } from "../../../components/misc/LocationBagde";
import { useAppSelector } from "../../../redux/hooks";
import { InsuranceTable } from "./InsuranceTable";
import { GetLeads } from "../../../redux/api";
import { setInsuranceCompanies, setLeads } from "../../../redux/reducers";
import { LeadModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import { GetInsurenceCompanies } from "../../../redux/api/insurance";
import { InsuranceCompany } from "../../../redux/types/InsuranceCompany";
import { AddCompany } from "./AddCompany";
import AppLoader from "../../../components/loader";

interface DashboardProps {}

export const InsuranceCompanies: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { insuranceCompanies } = userReducer;

  const [addCompany, setAddCompany] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onFetchLeads()
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching leads:", error);
        setIsLoading(false);
      });
  }, [addCompany]);

  const onFetchLeads = async () => {
    try {
      const data = await GetInsurenceCompanies();
      const response = data as {
        message: string;
        data: InsuranceCompany[];
      };
      console.log(response.data);
      if (Array.isArray(response.data)) {
        dispatch(setInsuranceCompanies(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDisplayAddCompany = () => {
    setAddCompany(!addCompany);
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    if (addCompany) {
      return (
        <Container>
          <AddCompany
            onTapAdd={() => {
              setAddCompany(false);
            }}
          />
        </Container>
      );
    } else {
      return (
        <Container>
          <InsuranceTable
            insuranceCompanies={
              Array.isArray(insuranceCompanies) ? insuranceCompanies : []
            }
            onTapAddCompany={() => onDisplayAddCompany()}
          />
        </Container>
      );
    }
  };

  return hostContainer();
};

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useState } from "react";
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { InsuranceCompany } from "../../../redux/types/InsuranceCompany";
import {
  CreateInsuranceCompany,
  GetInsurenceCompanies,
} from "../../../redux/api/insurance";
import { setInsuranceCompanies } from "../../../redux/reducers";

interface CreateCompanyProps {
  onTapAdd: Function;
  company?: InsuranceCompany;
}

export const AddCompany: React.FC<CreateCompanyProps> = ({
  onTapAdd,
  company,
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState(company?.company_name || "");
  const [link, setLink] = useState(company?.claim_link || "");
  const [logo, setLogo] = useState(company?.company_logo || "");

  const onTapCreateCompany = async () => {
    try {
      const data = await CreateInsuranceCompany({
        company_name: name,
        claim_link: link,
        company_logo: logo,
        id: undefined,
      });
      const userData = data as {
        message: string;
      };
      if (userData.message === "Created") {
        onFetchLeads();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchLeads = async () => {
    try {
      const data = await GetInsurenceCompanies();
      const userData = data as {
        message: string;
        data: InsuranceCompany[];
      };
      if (userData.data) {
        dispatch(setInsuranceCompanies(userData.data));
        onTapAdd();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hostContainer = () => {
    return (
      <ColDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            display: "row",
            maxWidth: "900px",

            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Spacer size={2} direction="row" />
            <ColDiv>
              <Lbl title="Create Insurance Company" size={25} bold={600} />
            </ColDiv>
          </RowDiv>
        </RowDiv>

        <Spacer size={1} direction="col" />

        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ColDiv
            style={{
              width: "60%",
              marginRight: "100px",
            }}
          >
            <TxtInput
              value={name}
              placeholder="Company Name"
              onChange={setName}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              value={link}
              placeholder="Claim Link"
              onChange={setLink}
            />
            <Spacer size={1} direction="col" />

            <TxtInput
              value={logo}
              placeholder="Company Logo"
              onChange={setLogo}
            />
            <Spacer size={1} direction="col" />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => onTapAdd()}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={company ? "Save" : "Create"}
            bgColor={company ? AppCSS.GLAS_BLUE : AppCSS.GLAS_BLUE}
            onTap={() => onTapCreateCompany()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  return hostContainer();
};

import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SegmentBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  background: none;
`;

export const StepperBg = styled.img`
  width: 70%;
  /* iPhone Retina */
  @media only screen and (min-width: 320px) {
    width: 70%;
    margin-top: 10px;
  }
  /* Small Devices, Phones */
  @media only screen and (min-width: 480px) {
    width: 60%;
    margin-top: 10px;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin-top: 20px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width: 992px) {
    margin-top: 30px;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width: 1200px) {
    margin-top: 30px;
  }
`;

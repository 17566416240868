import axios from "axios";
import { axiosAuth } from "./common";
import { BASE_URL } from "./appConst";
import { InsuranceCompany } from "../types/InsuranceCompany";
import { PaymentApprovalInput } from "../types";

export const GetInsurenceCompanies = async () => {
  try {
    const auth = axiosAuth(); // it will hev auth token
    const response = await auth.get(`${BASE_URL}/insurance_company`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const CreateInsuranceCompany = async (input: InsuranceCompany) => {
  try {
    const auth = axiosAuth();
    const response = await auth.post(`${BASE_URL}/insurance_company`, input);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const ApproveInsuranceScope = async (id: number) => {
  try {
    const auth = axiosAuth();
    const response = await auth.put(`${BASE_URL}/approve/scope/${id}`);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const ApprovePayment = async (
  pId: number,
  input: PaymentApprovalInput
) => {
  try {
    const auth = axiosAuth();
    const response = await auth.put(
      `${BASE_URL}/approve/payment/${pId}`,
      input
    );
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const RejectPayment = async (id: number, paymentType) => {
  try {
    const auth = axiosAuth();
    const response = await auth.put(
      `${BASE_URL}/reject/payment/${id}/${paymentType}`
    );
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

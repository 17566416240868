import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Project } from "../../../../redux/types";

interface RoofProps {
  project?: Project;
}

export const RoofDetails: FC<RoofProps> = ({ project }) => {
  const [isEdit, setIsEdit] = useState(false);

  // roof conditons
  const [roofCondition, setRoofCondition] = useState("");
  const [dateOfDamaged, setDateOfDamaged] = useState("");
  const [typeOfDamaged, setTypeOfDamaged] = useState("");

  useEffect(() => {
    if (project) {
      const { roof_condition, date_of_damaged, type_of_damaged } = project;

      if (roof_condition) {
        setRoofCondition(roofCondition);
      }

      if (date_of_damaged) {
        setDateOfDamaged(date_of_damaged);
      }
      if (type_of_damaged) {
        setTypeOfDamaged(type_of_damaged);
      }
    }
  }, [project]);

  const onTapUpdateProfile = () => {
    setIsEdit(false);
  };

  return (
    <>
      <RowDiv
        style={{
          display: "row",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ColDiv
          style={{
            width: "60%",
            marginRight: "100px",
          }}
        >
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl title="Roof Condition" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                disable={!isEdit}
                value={roofCondition}
                placeholder="Roof Condition"
                onChange={setRoofCondition}
              />
            </ColDiv>
            <Spacer size={1} direction="row" />
            <ColDiv>
              <Lbl title="Damaged By" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                disable={!isEdit}
                value={typeOfDamaged}
                placeholder="Damaged By"
                onChange={setTypeOfDamaged}
              />
            </ColDiv>
          </RowDiv>
          <ColDiv>
            <Lbl title="Date of Damage" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              disable={!isEdit}
              value={dayjs(dateOfDamaged).format("DD MMM YYYY")}
              placeholder="Damaged Date"
              onChange={setDateOfDamaged}
            />
          </ColDiv>
        </ColDiv>
        <ColDiv
          style={{
            width: "60%",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <ColDiv>
            <RowDiv
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Lbl title="Project Status" color={AppCSS.GRAY_DARK} size={13} />
            </RowDiv>
            <Chip
              style={{
                width: 180,
                height: 32,
                marginTop: 5,
                fontSize: 15,
              }}
              label="In Progress"
            ></Chip>
          </ColDiv>

          <Spacer size={2} direction="col" />
        </ColDiv>
      </RowDiv>
      <RowDiv style={{ justifyContent: "flex-end" }}>
        <TapButton
          title="Save"
          onTap={() => onTapUpdateProfile()}
          bgColor={AppCSS.GLAS_BLUE}
          width={120}
          height={38}
        />
      </RowDiv>
    </>
  );
};

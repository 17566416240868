import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import { FC, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Material } from "../../../../redux/types/Material";

interface MaterialProps {
  materials?: Material[];
}

export const MaterialDetails: FC<MaterialProps> = ({ materials }) => {
  const [isEdit, setIsEdit] = useState(false);
  const onTapUpdateProfile = () => {
    setIsEdit(false);
  };

  return (
    <>
      <RowDiv
        style={{
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <table style={{ width: "100%" }}>
          <tr
            style={{
              width: "100%",
              height: "40px",
              background: AppCSS.GLAS_BLUE,
            }}
          >
            <th style={{ color: "white", borderRadius: 10 }}>Material Brand</th>
            <th style={{ color: "white", borderRadius: 10 }}>Description</th>
            <th
              style={{
                minWidth: 200,
                color: "white",
                borderRadius: 10,
              }}
            >
              Price
            </th>

            <th style={{ color: "white", borderRadius: 10 }}>Warranty</th>
          </tr>

          {Array.isArray(materials) &&
            materials.map((item, index) => (
              <tr key={index} style={{ width: "100%", height: "40px" }}>
                <td style={{ paddingLeft: 5, textAlign: "center" }}>
                  <p>{item.brand}</p>
                </td>
                <td
                  style={{
                    paddingLeft: 5,
                    textAlign: "center",
                  }}
                >
                  <p>{item.description}</p>
                </td>
                <td
                  style={{
                    minWidth: 160,
                    textAlign: "center",
                    paddingLeft: 5,
                  }}
                >
                  <p
                    style={{
                      color: AppCSS.BLACK,
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      fontSize: "1rem",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    {item.price}
                  </p>
                </td>

                {item.warranty.length > 0 ? (
                  <td
                    style={{
                      paddingLeft: 5,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    <a
                      style={{ color: AppCSS.GLAS_BLUE }}
                      href={item.warranty}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>View Warranty</p>
                    </a>
                  </td>
                ) : null}
              </tr>
            ))}
        </table>
      </RowDiv>
    </>
  );
};

import { MenuItem } from "../types/interface";
import projectIcon from "../images/projectIcon.png";
import userIcon from "../images/userIcon.png";
import leadIcon from "../images/leadIcon.png";
import waitListsIcon from "../images/waitlistsIcon.png";
import logoutIcon from "../images/logoutIcon.png";
import settingIcon from "../images/settingsIcon.png";

import projectIconG from "../images/projectIcon_G.png";
import userIconG from "../images/userIcon_G.png";
import leadIconG from "../images/leadIcon_G.png";
import waitListsIconG from "../images/waitlistsIcon_G.png";
import logoutIconG from "../images/logoutIcon_G.png";
import settingIconG from "../images/settingsIcon_G.png";

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    text: "Leads",
    route: "/leads",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/8d6efa1c-c100-4507-8359-d25d94715300/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/63f25a4a-87a9-43ba-a8f3-efd70e63ee00/chatImage",
  },
  {
    id: 2,
    text: "Waitlist",
    route: "/waitlist",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/2b5c6f02-72f6-4164-b3d5-5d453d541800/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/d47a928e-12f5-43c2-2bd3-ccd1a1d82800/chatImage",
  },
  {
    id: 3,
    text: "Projects",
    route: "/projects",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/ed71e034-56c1-43ed-a8cd-ae2b8dd4d600/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/3e826582-9118-4752-ea2c-51ef55578d00/chatImage",
  },
  {
    id: 4,
    text: "Settings",
    route: "/settings",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/d5b0ff0c-8366-468c-6e83-f3b237517700/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/f1d05f96-4399-4604-10b5-a1094f84a700/chatImage",
    subMenu: [
      { text: "Materials", route: "/materials", icon: projectIcon },
      { text: "Insurance Companies", route: "/insurance", icon: projectIcon },
    ],
  },
  {
    id: 5,
    text: "Users",
    route: "/users",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/d7ebd233-d4f9-48f6-c151-1b6c4f7d7800/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/3dd57dab-636f-4f4d-dec6-27469ef32d00/chatImage",
  },
  {
    id: 6,
    text: "Logout",
    route: "/logout",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/904d01dc-bf5e-4fc9-50f5-fb0afa96cf00/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/2021d800-30b0-41bb-34ee-808eac3fc600/chatImage",
  },
];

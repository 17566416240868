/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import { useDispatch } from "react-redux";
import { Project } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  RowIconDiv,
  DashboardIcon,
  SearchInputBox,
  SearchIcon,
  PreviousIcon,
} from "../dashboard.styled";
import searchIcon from "../../../images/searchIcon.png";
import previousIcon from "../../../images/previousIcon.png";
import nextIcon from "../../../images/nextIcon.png";
import ProjectsTableHeaderCard from "../../../components/table/CustomTableHeaderCard";
import ProjectsTableBodyCard from "../../../components/table/CustomTableBodyCard";
import { PROJECTSTABLEHEADERLIST } from "../../../contants/dashboardHeader";

interface UserTableProps {
  projects: Project[];
}

export const ProjectTable: React.FC<UserTableProps> = ({ projects }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(projects)) {
      return projects.filter((item) => {
        const userName = `${item.user.first_name} ${item.user.last_name}`;
        return userName.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return projects;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filterLeads().length / rowsPerPage) - 1)
    );
  };

  const ProjectsTable = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
          }}
        >
          <ProjectsTableHeaderCard headers={PROJECTSTABLEHEADERLIST} />
          {filterLeads()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => {
              const address = `${row.address.street_address}, ${row.address.city}, ${row.address.state}`;
              return (
                <ProjectsTableBodyCard
                  key={`ProjectsDetails ${rowIndex}`}
                  bodyContent={[
                    { text: `${row.id}`, width: "10%" },
                    {
                      text: `${
                        row.user.first_name.charAt(0).toUpperCase() +
                        row.user.first_name.slice(1)
                      } ${
                        row.user.last_name.charAt(0).toUpperCase() +
                        row.user.last_name.slice(1)
                      }`,
                      width: "20%",
                    },
                    { text: `${row.user.phone}`, width: "20%" },
                    {
                      text: address,
                      width: "35%",
                    },
                    {
                      text: "",
                      width: "8%",


                      isAction: true,
                      actionComponent: (
                        <TapButton
                          title="View"
                          onTap={() => navigate(`/projects/${row.id}`)}

                          bgColor={"#65D191"}
                          borderColor={"#65D191"}
                          color={AppCSS.WHITE}
                          radius={5}
                          height={35}

                        />
                      ),
                    },
                  ]}
                />
              );
            })}
        </ColDiv>
        <RowDiv
          style={{
            justifyContent: "end",
            marginTop: "20px",
            marginBottom: "5%",
            alignItems: "center",
            marginRight: "10%",
          }}
        >
          <PreviousIcon src={previousIcon} onClick={() => goToPreviousPage()} />
          <span style={{ margin: "0 15px" }}>
            Page {page + 1} of {Math.ceil(filterLeads().length / rowsPerPage)}
          </span>
          <PreviousIcon src={nextIcon} onClick={() => goToNextPage()} />
        </RowDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            justifyContent: "space-around",
            marginLeft: "10px",
            marginRight: "auto",
          }}
        >
          <RowIconDiv
            style={{
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "3%",
              }}
            >
              <DashboardIcon
                src={
                  "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/3e826582-9118-4752-ea2c-51ef55578d00/chatImage"
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100px",
                  marginRight: "10px",
                }}
              >
                <Lbl title="Projects" size={22} width={80} />
              </Box>
            </Box>
            <SearchInputBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "white",

                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SearchIcon
                src={searchIcon}
                style={{
                  margin: "10px",
                  background: "none",
                }}
                onClick={handleSearchIconClick}
              />
              <TxtInput
                placeholder={`Search Name`}
                onChange={setKeyword}
                inputRef={inputRef}
                background="white"
              />
            </SearchInputBox>
          </RowIconDiv>

          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(projects) && ProjectsTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  return userContainer();
};

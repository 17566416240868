import { LeadDrawerDetails } from "../types/type";

export const TABLABELS: string[] = [
  "Customer Details",
  "Roof Details",
  "Insurance Scope",
  "Materials",
  "Estimate",
  "Installation Details",
  "Payment Details",
  "Documents",
  "COC / Invoice",
];

export const LEAD_DRAWER_DETAILS: LeadDrawerDetails[] = [
  { label: "First Name", key: "first_name", isDate: false },
  { label: "Last Name", key: "last_name", isDate: false },
  { label: "Email", key: "email", isDate: false },
  { label: "Phone", key: "phone", isDate: false },

  { label: "Street Address", key: "street_address", isDate: false },
  { label: "Unit", key: "unit", isDate: false },
  { label: "City", key: "city", isDate: false },
  { label: "State", key: "state", isDate: false },
  { label: "Zip Code", key: "zip_code", isDate: false },

  { label: "Roof Condition", key: "roof_condition", isDate: false },
  { label: "Type of Damage", key: "type_of_damaged", isDate: false },
  { label: "Date of Damage", key: "date_of_damaged", isDate: true },

  { label: "Ref Id", key: "ref_id", isDate: false },

  { label: "UTM", key: "utm", isDate: false },
  { label: "Created At", key: "created_at", isDate: true },
  { label: "Updated At", key: "updated_at", isDate: true },
];

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AppCSS, TxtInput } from "..";
import { Project, Docs } from "../../redux/types";
import { EstimateAddOns } from "../../redux/types/Estimate";
import { Spacer } from "../misc";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import {
  CreateEstimateAddOns,
  DeleteEstimateAddOns,
  GetEstimateAddOns,
} from "../../redux/api/estimate";
import { ColDiv, RowDiv } from "../misc/misc.styled";
import { TinyLbl } from "../labels/labels.styled";
import { useDispatch } from "react-redux";
import { setEstimateAddOns } from "../../redux/reducers";

interface ProjectAddOnsProps {
  title: string;
  AddOnType: "scope" | "invoice" | "credits";
  project?: Project;
  estimateAddOns?: EstimateAddOns[];
  docs?: Docs[];
}
export const ProjectAddOns: FC<ProjectAddOnsProps> = ({
  title,
  AddOnType,
  project,
  estimateAddOns,
  docs,
}) => {
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const dispatch = useDispatch();

  const onFetchEstimateAddOns = async () => {
    try {
      const data = await GetEstimateAddOns(project.id);
      const response = data.data as EstimateAddOns[];
      if (response) {
        dispatch(setEstimateAddOns(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (itemName.length < 1 || +itemPrice < 1) {
      toast("Please provide required input", {
        type: "error",
      });
      return;
    }

    try {
      const status = await CreateEstimateAddOns({
        project_id: project.id,
        item: itemName,
        price: +itemPrice,
        description: description,
        addon_type: AddOnType,
      });
      if (status === 201) {
        onFetchEstimateAddOns();
        toast("Item Created successfully!", {
          type: "success",
        });
      } else {
        toast("Failed to Create Item!", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Failed to Create Item!", {
        type: "error",
      });
    }
  };

  const onDeleteItem = async (id: number) => {
    try {
      const status = await DeleteEstimateAddOns(id);
      if (status === 200) {
        toast("Item Deleted successfully!", {
          type: "success",
        });
      } else {
        toast("Failed to Delete Item!", {
          type: "error",
        });
      }
      onFetchEstimateAddOns();
    } catch (error) {
      toast("Failed to Delete Item!", {
        type: "error",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          borderBottom: `2px solid ${AppCSS.GLAS_BLUE}`,
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <p
          style={{
            margin: 0,
          }}
        >
          {title}
        </p>
      </div>
      <RowDiv
        style={{
          flexDirection: "row",
          width: "98%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "1%",
          paddingRight: "1%",
        }}
      >
        <ColDiv>
          <TinyLbl>Item Name</TinyLbl>
          <TxtInput
            value={itemName}
            width={200}
            placeholder="Item Name"
            onChange={setItemName}
          />
        </ColDiv>
        <Spacer size={1} direction="row" />
        <ColDiv>
          <TinyLbl>Description</TinyLbl>
          <TxtInput
            value={description}
            width={300}
            placeholder="Description"
            onChange={setDescription}
          />
        </ColDiv>
        <Spacer size={1} direction="row" />
        <ColDiv>
          <TinyLbl>Price</TinyLbl>
          <TxtInput
            value={itemPrice}
            width={100}
            placeholder="Price"
            onChange={setItemPrice}
          />
        </ColDiv>
        <IconButton
          onClick={() => onTapSave()}
          style={{
            display: "flex",
            borderRadius: 24,
            height: 48,
            width: 48,
            justifyContent: "center",
            alignItems: "center",
            background: AppCSS.GLAS_BLUE,
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
          <AddIcon
            color="warning"
            style={{ height: 32, width: 32, color: AppCSS.WHITE }}
          />
        </IconButton>
      </RowDiv>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(estimateAddOns) &&
              estimateAddOns
                .filter((item) => item.addon_type === AddOnType)
                .map((item, i) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.item}
                    </TableCell>
                    <TableCell align="right">{item.description}</TableCell>
                    <TableCell align="right">{item.price}</TableCell>
                    <TableCell align="right">
                      {
                        <IconButton onClick={() => onDeleteItem(item.id)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer direction="col" size={1} />
    </div>
  );
};

import { Container } from "@mui/material";
import { AddMaterial } from "../Roofs/AddMaterial";
import { IncludesTable } from "./IncludesTable";
import { FC, useState } from "react";
import { Material } from "../../../../redux/types/Material";

interface IncludesMatProps {
  onDone: Function;
  materials?: Material[];
}

export const IncludesMaterials: FC<IncludesMatProps> = ({
  onDone,
  materials,
}) => {
  const [addMaterial, setAddMaterial] = useState(false);

  const onDisplayAddMaterial = () => {
    setAddMaterial(!addMaterial);
  };

  if (addMaterial) {
    return (
      <Container>
        <AddMaterial
          matType="includes"
          onCancel={() => setAddMaterial(false)}
          onTapAdd={() => {
            setAddMaterial(false);
            onDone("includes");
          }}
        />
      </Container>
    );
  } else {
    return (
      <IncludesTable
        materials={Array.isArray(materials) ? materials : []}
        onUploadIncludes={() => onDisplayAddMaterial()}
      />
    );
  }
};

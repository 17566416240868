import { ErrorHandler } from "./screens/ErrorHandler";
import { RouteManager } from "./screens/RouteManager";
import { AppAlertBox } from "./components/dialogue/AppAlertBox";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Providers } from "./redux/provider";

function App() {
  return (
    <Providers>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AppAlertBox />
      <ErrorHandler />
      <RouteManager />
    </Providers>
  );
}

export default App;

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  SelectPosition,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { AdminUserModel } from "../../../redux/types";
import { CreateAdminUser, EditAdminUser } from "../../../redux/api/users.api";

interface CreateUserProps {
  onTapAdd: Function;
  onFetchUsers: Function;
  editUser?: AdminUserModel;
}

export const CreateUser: React.FC<CreateUserProps> = ({
  onTapAdd,
  onFetchUsers,
  editUser,
}) => {
  const [isAdded, setIsAdded] = useState(false);

  const [firstName, setFirstName] = useState(editUser?.first_name || "");
  const [lastName, setLastName] = useState(editUser?.last_name || "");
  const [email, setEmail] = useState(editUser?.email || "");
  const [role, setRole] = useState(editUser?.role || "");
  const [phone, setPhone] = useState(editUser?.phone || "");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(editUser?.status || 0);

  useEffect(() => {
    if (isAdded) {
      setIsAdded(false);
      onTapAdd();
    }
  }, []);

  const onTapCreateUser = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      role === "" ||
      password === "" ||
      phone === ""
    ) {
      toast("Please enter mandatory fields!", {
        type: "error",
      });
      return;
    }

    try {
      const status = await CreateAdminUser({
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        role,
        password,
      });
      if (status === 200) {
        toast("user created successfully!", {
          type: "success",
        });
      }
      setIsAdded(false);
      onFetchUsers();
    } catch (error) {
      toast("error on create user!", {
        type: "error",
      });
    }
  };

  const onTapSaveUser = async () => {
    if (firstName === "" || lastName === "" || role === "" || phone === "") {
      toast("Please enter mandatory fields!", {
        type: "error",
      });
      return;
    }

    try {
      const statusCode = await EditAdminUser(editUser.id, {
        first_name: firstName,
        last_name: lastName,
        phone,
        role,
        status: status,
      });
      if (statusCode === 200) {
        toast("user updated successfully!", {
          type: "success",
        });
      }
      setIsAdded(false);
      onFetchUsers();
    } catch (error) {
      toast("error on create user!", {
        type: "error",
      });
    }
  };

  const hostContainer = () => {
    return (
      <ColDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            display: "row",
            maxWidth: "900px",

            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Spacer size={2} direction="row" />
            <ColDiv>
              <Lbl title="Create User" size={32} bold={600} />
              <Lbl
                title="A temporary password will be emailed to the user."
                size={10}
                color={AppCSS.BLACK}
              />
            </ColDiv>
          </RowDiv>
        </RowDiv>

        <Spacer size={1} direction="col" />

        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ColDiv
            style={{
              width: "60%",
              marginRight: "100px",
            }}
          >
            <TxtInput
              value={firstName}
              placeholder="First Name"
              onChange={setFirstName}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              value={lastName}
              placeholder="Last Name"
              onChange={setLastName}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              disable={editUser ? true : false}
              value={email}
              placeholder="Email"
              onChange={setEmail}
            />
            <Spacer size={1} direction="col" />
            <TxtInput value={phone} placeholder="Phone" onChange={setPhone} />
            <Spacer size={1} direction="col" />
            {!editUser && (
              <TxtInput
                value={password}
                placeholder="Password"
                onChange={setPassword}
              />
            )}
            <Spacer size={1} direction="col" />
            {editUser && (
              <p
                style={{ fontSize: 12, color: AppCSS.GRAY_DARK, marginLeft: 5 }}
              >
                Current Role:{role}
              </p>
            )}
            <SelectPosition value={role} onHandleChange={setRole.bind(this)} />
            <Spacer size={1} direction="col" />
            {editUser && (
              <RowDiv>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{
                    flexDirection: "row",
                    paddingLeft: "20px",
                  }}
                >
                  <FormControlLabel
                    value={1}
                    checked={status === 1}
                    control={
                      <Radio
                        onClick={() => {
                          setStatus(1);
                        }}
                        sx={{
                          color: "#dc2342",
                          "&.Mui-checked": {
                            color: "#dc2342",
                          },
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "1.0rem",
                        }}
                      >
                        Active
                      </p>
                    }
                  />
                  <FormControlLabel
                    value={0}
                    checked={status === 0}
                    control={
                      <Radio
                        onClick={() => {
                          setStatus(0);
                        }}
                        sx={{
                          color: "#dc2342",
                          "&.Mui-checked": {
                            color: "#dc2342",
                          },
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "1.0rem",
                        }}
                      >
                        Inactive
                      </p>
                    }
                  />
                </RadioGroup>
              </RowDiv>
            )}

            <Spacer size={2} direction="col" />
          </ColDiv>
        </RowDiv>
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => onTapAdd()}
            width={120}
            bgColor={AppCSS.WHITE}
            color={AppCSS.GLAS_BLUE}
            borderColor={AppCSS.GLAS_BLUE}
          />
          <TapButton
            title={editUser ? "Save" : "Create User"}
            bgColor={editUser ? AppCSS.GLAS_YELLOW : AppCSS.GLAS_BLUE}
            onTap={() => {
              if (editUser) {
                onTapSaveUser();
              } else {
                onTapCreateUser();
              }
            }}
            width={220}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
        <Spacer size={2} direction="col" />
      </ColDiv>
    );
  };

  return hostContainer();
};

/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import { FC, useState } from "react";
import { Container } from "../../../../globalstyled";
import {
  CreateVentsInput,
  Material,
  UpdateMaterial,
} from "../../../../redux/types/Material";
import PlaceHolder from "../../../../images/placeholder.jpeg";
import {
  CreateVents,
  GetMaterials,
  UpdateCurrentMaterial,
} from "../../../../redux/api/materials";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { setMaterials } from "../../../../redux/reducers";

interface UserTableProps {
  materials: Material[];
  onUploadVents: Function;
}

export const VentsTable: FC<UserTableProps> = ({
  materials,
  onUploadVents,
}) => {
  const dispatch = useDispatch();
  const [editVents, setEditVents] = useState(false);
  const [currentVent, setCurrentVent] = useState<Material>();

  const [isAddVents, setIsAddVents] = useState(false);
  const [material_type, setMaterial_type] = useState("vents");
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [coverage_size, setCoverageSize] = useState("");
  const [unit, setUnit] = useState("");
  const [home_photo, setHomePhoto] = useState("");

  const [keyword, setKeyword] = useState("");

  const resetForm = () => {
    setBrand("");
    setProduct("");
    setDescription("");
    setPrice("");
    setUnit("");
    setHomePhoto("");
    setCoverageSize("");
  };

  const onFetchMaterials = async () => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials(material_type);
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapCreateVents = async () => {
    const input: CreateVentsInput = {
      material_type,
      brand,
      product,
      price: price,
      description,
      coverage_size: +coverage_size,
      unit: unit,
      home_photo,
    };
    try {
      const result = await CreateVents(input);
      if (result) {
        setEditVents(false);
        setIsAddVents(false);
        await onFetchMaterials();
        toast("New Vent created!", {
          type: "success",
        });
      } else {
        setIsAddVents(false);
        toast("Error while creating Vent!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (!currentVent) {
      toast("Error while updating gutter!", {
        type: "error",
      });
      return;
    }
    const input: UpdateMaterial = {
      brand,
      product,
      description,
      price,
      coverage_size,
      unit,
      home_photo,
    };
    try {
      const result = await UpdateCurrentMaterial(currentVent.id, input);
      if (result === 200) {
        toast("Vent Updated Successfully!", {
          type: "success",
        });
        await onFetchMaterials();
        setEditVents(false);
        resetForm();
      } else {
        toast("Error while updating Vent!", {
          type: "error",
        });
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const AddVents = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.GLAS_BLUE,
              height: 20,
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Create Vents
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        {/* <RowDiv>
          <ColDiv>
            <Lbl title="Image Url" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={home_photo}
              placeholder="image url"
              onChange={setHomePhoto}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" /> */}
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => setIsAddVents(false)}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Create"}
            bgColor={AppCSS.GLAS_BLUE}
            onTap={() => onTapCreateVents()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const EditVents = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: AppCSS.GLAS_BLUE,
              height: 20,
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 600,
                color: AppCSS.WHITE,
              }}
            >
              Edit Vents
            </p>
          </div>
        </div>
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        {/* <RowDiv>
          <ColDiv>
            <Lbl title="Image Url" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={home_photo}
              placeholder="image url"
              onChange={setHomePhoto}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" /> */}
        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <TapButton
            title="Cancel"
            onTap={() => {
              setEditVents(false);
              setIsAddVents(false);
            }}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Save"}
            bgColor={AppCSS.GLAS_BLUE}
            onTap={() => onTapSave()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(materials)) {
      return materials.filter((item) => {
        return item.product.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return materials;
  };

  const viewImage = (imageName?: string) => {
    // console.log(url);
    return imageName ? imageName : PlaceHolder;
  };

  const MaterialsTable = () => {
    return (
      <div style={{ width: "100%", marginBottom: "5%" }}>
        <RowDiv
          style={{
            marginTop: 20,
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <TxtInput
            width={280}
            placeholder={`Search vents`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Upload"}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              onTap={() => {
                setIsAddVents(false);
                onUploadVents();
              }}
              width={80}
              height={40}
            />
            <TapButton
              title={"Add Vents"}
              bgColor={AppCSS.GLAS_BLUE}
              onTap={() => {
                resetForm();
                setIsAddVents(true);
              }}
              width={120}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <table style={{ width: "100%" }}>
            <tr
              style={{
                height: "40px",
                color: "white",
                background: AppCSS.GLAS_BLUE,
              }}
            >
              <th style={{ borderRadius: 10 }}>Brand</th>
              <th style={{ borderRadius: 10 }}>Product</th>
              <th style={{ borderRadius: 10 }}>Description</th>
              <th style={{ borderRadius: 10 }}>Price</th>
              {/* <th>Coverage Size</th> */}
              <th style={{ borderRadius: 10 }}>Unit</th>
              {/* <th style={{ borderRadius: 10 }}>Photo</th> */}
              <th style={{ borderRadius: 10 }}>Action</th>
            </tr>
            {filterLeads()
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const key = `key-${row.id}`;
                return (
                  <tr key={key} style={{ width: "100%", height: "40px" }}>
                    <td>
                      <p>{row.brand}</p>
                    </td>
                    <td>
                      <p>{row.product}</p>
                    </td>
                    <td>
                      <p
                        style={{
                          color: AppCSS.BLACK,
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          fontSize: "0.9rem",
                          margin: 0,
                          padding: 10,
                        }}
                      >
                        {row.description}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>${row.price}</p>
                    </td>
                    {/* <td>
                      <p>{0}</p>
                    </td> */}
                    <td style={{ textAlign: "center" }}>
                      <p>{row.unit}</p>
                    </td>
                    {/* <td>
                      <p>
                        <img
                          src={viewImage(
                            Array.isArray(row.Colors)
                              ? row.Colors[0].home_photo
                              : ""
                          )}
                          width={80}
                          height={60}
                          alt="logo"
                        />
                      </p>
                    </td> */}
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setPrice(row.price);
                          setProduct(row.product);
                          setDescription(row.description);
                          setUnit(row.unit);
                          setCurrentVent(row);
                          setEditVents(true);
                        }}
                        aria-label=""
                        style={{
                          display: "flex",
                          width: 48,
                          height: 48,
                          border: 1,
                          borderColor: AppCSS.GRAY_MILD,
                        }}
                      >
                        <EditIcon style={{ color: AppCSS.GLAS_GREEN }} />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
          </table>
        </RowDiv>
      </div>
    );
  };

  const materialsContainer = () => {
    return (
      <Container>
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {Array.isArray(materials) && MaterialsTable()}
        </ColDiv>
      </Container>
    );
  };

  if (isAddVents) {
    return AddVents();
  } else if (editVents) {
    return EditVents();
  } else {
    return materialsContainer();
  }
};

/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import { useDispatch } from "react-redux";
import { LeadModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import { InsuranceCompany } from "../../../redux/types/InsuranceCompany";
import { useNavigate } from "react-router-dom";

interface UserTableProps {
  insuranceCompanies: InsuranceCompany[];
  onTapAddCompany: Function;
}

export const InsuranceTable: React.FC<UserTableProps> = ({
  insuranceCompanies,
  onTapAddCompany,
}) => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(insuranceCompanies)) {
      return insuranceCompanies.filter((item) => {
        return item.company_name.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return insuranceCompanies;
  };

  const InsuranceTable = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <TxtInput
            width={280}
            placeholder={`Search Company`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Add Company"}
              bgColor={AppCSS.GLAS_BLUE}
              onTap={() => onTapAddCompany()}
              width={200}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <table style={{ width: "100%", marginBottom: "5%" }}>
            <tr
              style={{
                width: "100%",
                height: "40px",
                background: AppCSS.GLAS_BLUE,
                color: "white",
              }}
            >
              <th style={{ minWidth: 300, borderRadius: 10 }}>Company Name</th>
              <th style={{ borderRadius: 10, minWidth: 200 }}>Company Logo</th>
              <th style={{ borderRadius: 10 }}>Claim Link</th>
              <th style={{ borderRadius: 10 }}>Action</th>
            </tr>
            {filterLeads()
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const key = `key-${row.id}`;
                return (
                  <tr key={key} style={{ width: "100%", height: "40px" }}>
                    <td style={{ minWidth: 320 }}>
                      <p>{row.company_name}</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={row.company_logo}
                        width={80}
                        height={60}
                        alt="logo"
                      />
                    </td>
                    <td>
                      <a
                        href={row.claim_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: AppCSS.BLACK,
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          fontSize: "0.9rem",
                          margin: 0,
                          padding: 10,
                        }}
                      >
                        View claim site
                      </a>
                    </td>
                    <td>
                      <TapButton
                        title="Edit"
                        onTap={() => {}}
                        bgColor={AppCSS.WHITE}
                        borderColor={AppCSS.GLAS_GREEN}
                        color={AppCSS.GLAS_GREEN}
                        radius={10}
                        height={40}
                      />
                    </td>
                  </tr>
                );
              })}
          </table>
        </RowDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Lbl title="Insurance Companies" size={22} />
          </RowDiv>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(insuranceCompanies) && InsuranceTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  return userContainer();
};

/* eslint-disable jsx-a11y/alt-text */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppCSS, Lbl, Spacer, TapButton } from "../components";
import { ColDiv } from "../components/misc/misc.styled";
import PageNotFoundIcon from "../images/404.jpeg";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ColDiv
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spacer size={5} direction="col" />
      <img src={PageNotFoundIcon} width={320} height={280} />
      <Lbl
        title="The page your are looking for does not exist!"
        align="center"
        size={16}
        color={AppCSS.GRAY}
      />
      <Spacer size={5} direction="col" />
      <TapButton
        width={180}
        height={42}
        radius={30}
        title="Continue"
        onTap={() => {
          // dispatch(onLogout());
          setTimeout(() => {
            navigate("/");
          });
        }}
      />
    </ColDiv>
  );
};

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Progress, Project } from "../../../../redux/types";
import { FC, useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import { Prompt } from "../../../../components/dialogue/Dialogue";
import { EditProgressSteps } from "../../../../redux/api";

interface CustomerProps {
  onFetchProgress: Function;
  progress?: Progress[];
  project?: Project;
}

interface StepChangeAction {
  step: string;
  forward: boolean;
}

export const CustomerDetails: FC<CustomerProps> = ({
  onFetchProgress,
  project,
  progress,
}) => {
  // customer details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [email, setEmail] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [stepToChange, setStepToChange] = useState<StepChangeAction>();
  const [stepChangeMsg, setStepChangeMsg] = useState(
    "This will revert or advance the project to the selected step."
  );

  useEffect(() => {
    if (project) {
      const { first_name, last_name, phone, email } = project.user;
      const { street_address, city, state } = project.address;
      if (first_name) {
        setFirstName(first_name);
      }
      if (last_name) {
        setLastName(last_name);
      }
      if (phone) {
        setPhoneNumber(phone);
      }
      if (email) {
        setEmail(email);
      }

      if (street_address) {
        setStreetAddress(street_address);
      }
      if (city) {
        setCityName(city);
      }
      if (state) {
        setStateName(state);
      }
    }
  }, [project]);

  const onTapUpdateProfile = () => {
    setIsEdit(false);
  };

  const changeCustomerStage = (stage: StepChangeAction) => {
    setStepChangeMsg(
      `This will ${
        stage.forward ? "advance" : "revert"
      } the project to the selected step.`
    );
    setOpenConfirm(true);
    setStepToChange(stage);
  };

  const customerChangeConfirmation = async () => {
    setOpenConfirm(false);
    try {
      const status = await EditProgressSteps(
        project.id,
        stepToChange.step,
        stepToChange.forward ? "forward" : "reverse"
      );
      if (status === 200) {
        onFetchProgress();
      }
      // TODO: show errors
    } catch (error) {}
  };

  const displaySteps = () => {
    if (Array.isArray(progress)) {
      return progress.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={item.completed}
              onChange={() => {
                changeCustomerStage({
                  step: item.goal,
                  forward: !item.completed,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
          label={`${item.goal.replaceAll("_", " ").toUpperCase()}`}
        />
      ));
    } else {
      return <></>;
    }
  };

  return (
    <>
      <RowDiv
        style={{
          display: "row",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Prompt
          message={stepChangeMsg}
          onConfirm={() => {
            customerChangeConfirmation();
          }}
          onClose={() => {
            setStepToChange({} as StepChangeAction);
            setOpenConfirm(false);
          }}
          open={openConfirm}
        />
        <ColDiv
          style={{
            width: "60%",
            marginRight: "100px",
          }}
        >
          {/* right side */}
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl title="First Name" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                disable={!isEdit}
                value={firstName}
                placeholder="First Name"
                onChange={setFirstName}
              />
            </ColDiv>
            <Spacer size={1} direction="row" />
            <ColDiv>
              <Lbl title="Last Name" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                disable={!isEdit}
                value={lastName}
                placeholder="Last Name"
                onChange={setLastName}
              />
            </ColDiv>
          </RowDiv>
          <ColDiv>
            <Lbl title="Phone Number" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              disable={!isEdit}
              value={phoneNumber}
              placeholder="Phone Number"
              onChange={setPhoneNumber}
            />
          </ColDiv>
          <ColDiv>
            <Lbl title="Email" color={AppCSS.GRAY_DARK} size={13} />

            <TxtInput
              value={email}
              placeholder="Email"
              onChange={setEmail}
              disable
            />
          </ColDiv>
          <Spacer size={1} direction="col" />
        </ColDiv>
        <ColDiv
          style={{
            width: "60%",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <ColDiv>
            <RowDiv
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Lbl title="Address" color={AppCSS.GRAY_DARK} size={13} />
            </RowDiv>

            <TxtInput
              value={streetAddress}
              disable={!isEdit}
              placeholder="Street Address"
              onChange={setStreetAddress}
            />
          </ColDiv>
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl title="City Name" color={AppCSS.GRAY_DARK} size={13} />

              <TxtInput
                disable={!isEdit}
                value={cityName}
                placeholder="City Name"
                onChange={setCityName}
              />
            </ColDiv>
            <Spacer size={1} direction="row" />
            <ColDiv>
              <Lbl title="State Name" color={AppCSS.GRAY_DARK} size={13} />

              <TxtInput
                disable={!isEdit}
                value={stateName}
                placeholder="State Name"
                onChange={setStateName}
              />
            </ColDiv>
          </RowDiv>

          <Spacer size={2} direction="col" />
        </ColDiv>
      </RowDiv>
      <RowDiv style={{ justifyContent: "flex-end" }}>
        <TapButton
          title="Save"
          onTap={() => onTapUpdateProfile()}
          bgColor={AppCSS.GLAS_BLUE}
          width={120}
          height={38}
        />
      </RowDiv>
      <Spacer size={2} direction="col" />
      <RowDiv
        style={{
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            marginBottom: 10,
            fontSize: 20,
          }}
        >
          Customer Steps
        </div>
        {displaySteps()}
      </RowDiv>
    </>
  );
};
